export enum PortletType {
    EXPANDABLETABLE = 'EXPANDABLETABLE',
    PIE = 'PIE',
    REGIONAL = 'REGIONAL',
    ALLOCATIONCHECKS = 'ALLOCATIONCHECKS',
    AUTORECOMMENDATION = 'AUTORECOMMENDATION',
    RIRECOMMENDATION = 'RIRECOMMENDATION',
    OPTIMIZERCHECKS = 'OPTIMIZERCHECKS',
    RIBBON = 'RIBBON',
    AGGREGATE = 'AGGREGATE',
    OVERVIEW = 'OVERVIEW',
    BARGRAPH = 'BARGRAPH',
    VIEWS = 'VIEWS',
    REPORT_LISTING = 'REPORT_LISTING',
    MANAGED_REPORT_LISTING = 'MANAGED_REPORT_LISTING',
    TAGCONFIGURATION = 'TAGCONFIGURATION',
    CUSTOMER_PROFILE = 'CUSTOMER_PROFILE',
    USER_PROFILE = 'USER_PROFILE',
    GRAPH = 'GRAPH',
    CUSTOMERS_LISTING = 'CUSTOMERS_LISTING',
    AUTOMATION = 'AUTOMATION',
    USERS_LISTING = 'USERS_LISTING',
    DRILLABLE_TABLE = 'DRILLABLETABLE',
    WHITELABEL_INTEGRATION = 'WHITELABEL_INTEGRATION',
    ALERT_LISTING = 'ALERT_LISTING',
    BUDGET_PIE = 'BUDGET_PIE',
    BUDGET_REGION = 'BUDGET_REGION',
    BUDGET_TIMESERIES = 'BUDGET_TIMESERIES',
    GMAIL_INTEGRATION = 'GMAIL_INTEGRATION',
    O365_INTEGRATION = 'O365_INTEGRATION',
    SLACK_INTEGRATION = 'SLACK_INTEGRATION',
    FRESHDESK_INTEGRATION = 'FRESHDESK_INTEGRATION',
    FRESHSERVICE_INTEGRATION = 'FRESHSERVICE_INTEGRATION',
    ZENDESK_INTEGRATION = 'ZENDESK_INTEGRATION',
    SNS_INTEGRATION = 'SNS_INTEGRATION',
    SERVICENOW_INTEGRATION = 'SERVICENOW_INTEGRATION',
    FRESHDESK_TICKET_LISTING = 'FRESHDESK_TICKET_LISTING',
    FRESHSERVICE_TICKET_LISTING = 'FRESHSERVICE_TICKET_LISTING',
    ZENDESK_TICKET_LISTING = 'ZENDESK_TICKET_LISTING',
    SERVICENOW_REQUESTS = 'SERVICENOW_REQUESTS',
    SERVICENOW_INCIDENTS = 'SERVICENOW_INCIDENTS',
    NAGIOS_INTEGRATION = 'NAGIOS_INTEGRATION',
    CSP_INTEGRATION = 'CSP_INTEGRATION',
    EA_INTEGRATION = 'EA_INTEGRATION',
    PLAN_INTEGRATION = 'PLAN_INTEGRATION',
    AWS_INTEGRATION = 'AWS_INTEGRATION',
    AWS_ACCOUNT_LISTING = 'AWS_ACCOUNT_LISTING',
    CSP_ACCOUNT_LISTING = 'CSP_ACCOUNT_LISTING',
    EA_ACCOUNT_LISTING = 'EA_ACCOUNT_LISTING',
    CREATE_INSTANCE_PROVISIONING = 'CREATE_INSTANCE_PROVISIONING',
    CREATE_VOLUME_PROVISIONING = 'EC2_VOLUME_PROVISIONING',
    TAGGING_AUTOMATION = 'TAGGING_AUTOMATION',
    AZURE_PROVISIONING_RESOURCES = 'AZURE_PROVISIONING_RESOURCES',
    AWS_EC2_VOLUME_PROVISIONING = 'AWS_EC2_VOLUME_PROVISIONING',
    AWS_CREATE_INSTANCE_PROVISIONING = 'AWS_CREATE_INSTANCE_PROVISIONING',
    CUSTOMER_SERVICE_LISTING = 'CUSTOMER_SERVICE_LISTING',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    AZURE_CREATE_DISK_PROVISIONING = 'AZURE_CREATE_DISK_PROVISIONING',
    ZOHO_INTEGRATION = 'ZOHO_INTEGRATION',
    JIRADESK_INTEGRATION = 'JIRADESK_INTEGRATION',
    JIRADESK_TICKET_LISTING = 'JIRADESK_TICKET_LISTING',
    AZURE_CREATE_VM_PROVISIONING = 'AZURE_CREATE_VM_PROVISIONING',
    FILTERS = 'FILTERS',
    GENERAL_CONFIGURATION = 'GENERAL_CONFIGURATION',
    ZOHO_TICKET_LISTING = 'ZOHO_TICKET_LISTING',
    OTRS_INTEGRATION = 'OTRS_INTEGRATION',
    SERVICE_CATALOG = 'SERVICE_CATALOG',
    AUTOTASK_INTEGRATION = 'AUTOTASK_INTEGRATION',
    AUTOTASK_TICKET_LISTING = 'AUTOTASK_TICKET_LISTING',
    OTRS_TICKET_LISTING = 'OTRS_TICKET_LISTING',
    AZURE_RIRECOMMENDATION = 'AZURE_RIRECOMMENDATION',
    SUB_DOMAIN = 'SUB_DOMAIN',
    SAML_INTEGRATION = 'SAML_INTEGRATION',
    CASDM_INTEGRATION = 'CASDM_INTEGRATION',
    CASDM_TICKET_LISTING = 'CASDM_TICKET_LISTING',
    CUSTOM_COMPLIANCE = 'CUSTOM_COMPLIANCE',
    ROOT_USER_MFA = 'ROOT_USER_MFA',
    USER_MFA = 'USER_MFA',
    LOGIN_METRICS = 'LOGIN_METRICS',
    ADOPTION_METRICS = 'ADOPTION_METRICS',
    GCP_INTEGRATION = 'GCP_INTEGRATION',
    GCP_ACCOUNT_LISTING = 'GCP_ACCOUNT_LISTING',
    MY_PRICE_CONFIGURATION = 'MY_PRICE_CONFIGURATION',
    CHARGE_TYPE = 'CHARGE_TYPE',
    AZURE_ARM_JSON_TEMPLATE = 'AZURE_ARM_JSON_TEMPLATE',
    WIDGET_CATALOG = 'WIDGET_CATALOG',
    DEPLOY_WIDGET = 'DEPLOY_WIDGET',
    AUDIT = 'AUDIT',
    ASSESSMENT = 'ASSESSMENT',
    ACCESS_CONTROL_LIST = 'ACCESS_CONTROL_LIST',
    SPONSORSHIP_INTEGRATION = 'SPONSORSHIP_INTEGRATION',
    SPONSORSHIP_LISTING = 'SPONSORSHIP_LISTING',
    PAY_AS_YOU_GO_LISTING = 'PAY_AS_YOU_GO_LISTING',
    PAY_AS_YOU_GO_INTEGRATION = 'PAY_AS_YOU_GO_INTEGRATION',
    CUSTOM_COST_MONITORING_CREATION = 'CUSTOM_COST_MONITORING_CREATION',
    SINGLE_STACKED_BAR = 'SINGLE_STACKED_BAR',
    MCA_INTEGRATION = 'MCA_INTEGRATION',
    WIDGET_CREATE_TEMPLATE = 'WIDGET_CREATE_TEMPLATE',
    ADD_WIDGET_DOCUMENTATION = 'ADD_WIDGET_DOCUMENTATION',
    TERRAFORM_WIDGET_LISTING = 'TERRAFORM_WIDGET_LISTING',
    TERRAFORM_RUNBOOK = 'TERRAFORM_RUNBOOK',
    SALESFORCE_INTEGRATION = 'SALESFORCE_INTEGRATION',
    SALESFORCE_LISTING = 'SALESFORCE_LISTING',
    USERS_LISTING_ASSESSMENT = 'USERS_LISTING_ASSESSMENT',
    MOCK_WIDGET = 'MOCK_WIDGET',
    ASSESSMENT_ASSESSEE = 'ASSESSMENT_ASSESSEE',
    EMAIL_TEMPLATES = 'EMAIL_TEMPLATES'
}
