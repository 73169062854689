import { PortletType } from 'src/app/shared/enums/PortletType';

export interface IDataInterface {
    description: string;
    features: string[];
}
export class FullAccessData {
    static visibilityFullAccessData: IDataInterface = {
        description: `Cloud Visibility aggregates terabytes of unstructured data
                     into interactive graphical dashboards and customized reports
                     that can be downloaded in various formats. The full version
                     will provide you with the following features`,
        features: [
            '360-degree visibility of the cloud infrastructure.',
            'Access to download reports in formats like CSV, PDF, Excel..',
            'Analyse data along with insightful data graphs and compare views.',
            'Access to download reports unlimited times.',
            'Access to Add to Cart, to perform operations in bulk.'
        ]
    };
    static tagConfigurationFullAccessData: IDataInterface = {
        description: `Tag Management provides complete visibility of tagged, untagged, and mistagged resources in a centralized dashboard. The full version will provide you with the following features`,
        features: [
            'Visibility of the tags corresponding to all the resources',
            'Access to define unlimited tagging nomenclatures.',
            'Visibility to all the mistagged and untagged resources.',
            'Perform Add/Update & Delete tags actions in bulk.',
            'Access to create unlimited auto-tagging rules.',
            'Access to Add to Cart to perform operations in bulk.'
        ]
    };
    static governanceFullAccessData: IDataInterface = {
        description: `Governance & Reporting acts as a watchdog for your
                    infrastructure. It allows you to set up security and
                    budget alerts and create reports that can be automatically
                    shared with the respective stakeholders. The full version
                    will provide you with the following features.`,
        features: [
            'Access to more than 500+ pre-defined Reports in multiple formats.',
            'Access to create and schedule multiple report groups.',
            'Access to define multiple alert rules against security checks.',
            'Access to define multiple budget configurations for individual services.',
            'Access to Add to Cart to perform operations in bulk.'
        ]
    };

    static longTermSavings: IDataInterface = {
        description: `Long Term Savings maximizes the ROI of your cloud by
            recommending reservations on your constantly running workloads. The
            full version will provide you with the following features.`,
        features: [
            `Access to all reservation purchase recommendations.`,
            `RI consumption visibility for individual RIs purchased.`,
            `Access to purchase RI from the console.`,
            `Access to download reports unlimited times.`,
            `Access to Add to Cart to perform operations in bulk`
        ]
    };

    static fullAccessMap = new Map<PortletType, IDataInterface>()
        .set(PortletType.PIE, FullAccessData.visibilityFullAccessData)
        .set(PortletType.REGIONAL, FullAccessData.visibilityFullAccessData)
        .set(
            PortletType.DRILLABLE_TABLE,
            FullAccessData.visibilityFullAccessData
        )
        .set(
            PortletType.TAGGING_AUTOMATION,
            FullAccessData.tagConfigurationFullAccessData
        )
        .set(
            PortletType.TAGCONFIGURATION,
            FullAccessData.tagConfigurationFullAccessData
        )
        .set(
            PortletType.EXPANDABLETABLE,
            FullAccessData.visibilityFullAccessData
        )
        .set(
            PortletType.ALLOCATIONCHECKS,
            FullAccessData.tagConfigurationFullAccessData
        )
        .set(
            PortletType.REPORT_LISTING,
            FullAccessData.governanceFullAccessData
        )
        .set(PortletType.ALERT_LISTING, FullAccessData.governanceFullAccessData)
        .set(PortletType.BUDGET_PIE, FullAccessData.governanceFullAccessData)
        .set(PortletType.BUDGET_REGION, FullAccessData.governanceFullAccessData)
        .set(
            PortletType.BUDGET_TIMESERIES,
            FullAccessData.governanceFullAccessData
        )
        .set(PortletType.RIRECOMMENDATION, FullAccessData.longTermSavings)
        .set(PortletType.AZURE_RIRECOMMENDATION, FullAccessData.longTermSavings)
        .set(PortletType.OPTIMIZERCHECKS, {
            description: `Wastage Tracker eliminates cost leaks incurred from orphaned resources
            lying unnecessarily in the cloud infrastructure. The full version will
            provide you with the following features.`,
            features: [
                `Visibility of all the underutilized and idle resources creating cost leaks.`,
                `Access to perform remediation operations in bulk.`,
                `Access to download reports unlimited times.`,
                `Access to Add to Cart to perform operations in bulk.`
            ]
        })
        .set(PortletType.AUTORECOMMENDATION, {
            description: `Scheduling Engine analyzes resource usage patterns and
            recommends running schedules for cloud resources to save costs. The
            full version will provide you with the following features.`,
            features: [
                `Access to multiple resources recommendations incurring cost when idle.`,
                `Access to download reports unlimited times.`,
                `Analyze resource usage patterns using graphs.`,
                `Access to Add to Cart to perform operations in bulk.`
            ]
        });
}
