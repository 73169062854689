import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSliderModule } from '@m0t0r/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    DropDownListModule,
    MultiSelectAllModule
} from '@syncfusion/ej2-angular-dropdowns';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AggridTooltipComponent } from '../../components/aggrid-tooltip/aggrid-tooltip.component';
import { CardsGeneratorComponent } from '../../components/cards-generator/cards-generator.component';
import { ColorPickerComponent } from '../../components/color-picker/color-picker.component';
import { CommonIntegrationsIndexComponent } from '../../components/common-integrations-index/common-integrations-index.component';
import { DateRangeFilterComponent } from '../../components/custom-filters/date-range-filter/date-range-filter.component';
import { DateTimeRangeComponent } from '../../components/date-time-range/date-time-range.component';
import { DependentDropdownsComponent } from '../../components/dependent-dropdowns/dependent-dropdowns.component';
import { DescriptionGeneratorComponent } from '../../components/description-generator/description-generator.component';
import { GroupingDropdownComponent } from '../../components/dropdowns/grouping-dropdown/grouping-dropdown.component';
import { MultiGroupDropdownComponent } from '../../components/dropdowns/multi-group-dropdown/multi-group-dropdown.component';
import { MultiSelectDropdownComponent } from '../../components/dropdowns/multi-select-dropdown/multi-select-dropdown.component';
import { SingleSelectCategoryDropdownComponent } from '../../components/dropdowns/single-select-category-dropdown/single-select-category-dropdown.component';
import { EmailIdInputsComponent } from '../../components/email-id-inputs/email-id-inputs.component';
import { FieldGeneratorHandlerComponent } from '../../components/field-generator-handler/field-generator-handler.component';
import { FieldGeneratorComponent } from '../../components/field-generator/field-generator.component';
import { AclViewLayerFilterComponent } from '../../components/filters/acl-view-layer-filter/acl-view-layer-filter.component';
import { CurrencyFilterComponent } from '../../components/filters/currency-filter/currency-filter.component';
import { CustomerUserListingFilterComponent } from '../../components/filters/customer-user-listing-filter/customer-user-listing-filter.component';
import { SingleDropdownPercentFilterComponent } from '../../components/filters/single-dropdown-percent-filter/single-dropdown-percent-filter.component';
import { FormBuilderComponent } from '../../components/form-builder/form-builder.component';
import { KanbanGeneratorComponent } from '../../components/kanban-generator/kanban-generator.component';
import { ModalInfoTemplateComponent } from '../../components/modal-templates/modal-info-template/modal-info-template.component';
import { DatePickerComponent } from '../../components/modal-templates/service-catalog/date-picker/date-picker.component';
import { ServicenowTableComponent } from '../../components/modal-templates/service-catalog/servicenow-table/servicenow-table.component';
import { NLevelTraversalMenuViewComponent } from '../../components/n-level-traversal-menu-view/n-level-traversal-menu-view.component';
import { ToastComponent } from '../../components/notifications/toast/toast.component';
import { ObjectCreatorComponent } from '../../components/object-creator/object-creator.component';
import { OtherIntegrationsThresholdComponent } from '../../components/other-integrations-threshold/other-integrations-threshold.component';
import { RequestMediumsComponent } from '../../components/request-mediums/request-mediums.component';
import { SlackThresholdComponent } from '../../components/slack-threshold/slack-threshold.component';
import { SnsThresholdComponent } from '../../components/sns-threshold/sns-threshold.component';
import { TagGeneratorComponent } from '../../components/tag-generator/tag-generator.component';
import { TeamsThresholdComponent } from '../../components/teams-threshold/teams-threshold.component';
import { ThresholdEmailComponent } from '../../components/threshold-email-budgeting/threshold-email.component';
import { ToggleComponent } from '../../components/toggle/toggle.component';
import { VerticalScrollingTabGroupComponent } from '../../components/vertical-scrolling-tab-group/vertical-scrolling-tab-group.component';
import { WidgetGeneratorComponent } from '../../components/widget-generator/widget-generator.component';
import { ValueFormatterDirective } from '../../directives/value-formatter/value-formatter.directive';
import { BootstrapModule } from '../bootstrap/bootstrap.module';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { FormModule } from '../form/form.module';
import { HelperComponentModule } from '../helper-component/helper-component.module';
import { MaterialModule } from '../material/material-module.module';
import { CarouselGeneratorComponent } from './../../components/carousel-generator/carousel-generator.component';
import { FiltersHandlerComponent } from './../../components/filters-handler/filters-handler.component';
import { FiltersTemplateComponent } from './../../components/filters-template/filters-template.component';
import { WidgetSelectorComponent } from './../../components/widget-selector/widget-selector.component';
import { GraphModule } from './../graph/graph.module';
import { MultiCurrencyFilterComponent } from '../../components/filters/multi-currency-filter/multi-currency-filter.component';
import { AssessmentInfoBarComponent } from '../../components/modal-templates/assessee-view/assessment-info-bar/assessment-info-bar.component';

const components = [
    MultiSelectDropdownComponent,
    ToggleComponent,
    GroupingDropdownComponent,
    FieldGeneratorComponent,
    SingleSelectCategoryDropdownComponent,
    MultiGroupDropdownComponent,
    DescriptionGeneratorComponent,
    ObjectCreatorComponent,
    NLevelTraversalMenuViewComponent,
    ValueFormatterDirective,
    CurrencyFilterComponent,
    DateRangeFilterComponent,
    AggridTooltipComponent,
    EmailIdInputsComponent,
    OtherIntegrationsThresholdComponent,
    SlackThresholdComponent,
    SnsThresholdComponent,
    TeamsThresholdComponent,
    ThresholdEmailComponent,
    CardsGeneratorComponent,
    CommonIntegrationsIndexComponent,
    WidgetSelectorComponent,
    FiltersHandlerComponent,
    DatePickerComponent,
    ServicenowTableComponent,
    ModalInfoTemplateComponent,
    FieldGeneratorHandlerComponent,
    DependentDropdownsComponent,
    TagGeneratorComponent,
    ColorPickerComponent,
    KanbanGeneratorComponent,
    CarouselGeneratorComponent,
    AclViewLayerFilterComponent,
    WidgetGeneratorComponent,
    RequestMediumsComponent,
    FormBuilderComponent,
    FiltersTemplateComponent,
    VerticalScrollingTabGroupComponent,
    SingleDropdownPercentFilterComponent,
    DateTimeRangeComponent,
    CustomerUserListingFilterComponent,
    ToastComponent,
    MultiCurrencyFilterComponent,
    AssessmentInfoBarComponent
];

const modules = [
    CommonModule,
    NgSelectModule,
    HelperComponentModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapModule,
    MaterialModule,
    MultiSelectAllModule,
    DropDownListModule,
    NgxSliderModule,
    NgxGaugeModule,
    RouterModule,
    MaterialFileInputModule,
    NgxSummernoteModule,
    NgxMatTimepickerModule,
    MonacoEditorModule.forRoot(),
    CommonPipesModule,
    FormModule,
    GraphModule
];
@NgModule({
    declarations: [...components],
    imports: [...modules],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [...components]
})
export class UtilsModule {}
