export class GlobalConfiguration {
    static readonly WIDGETS_PAGE_LIMIT = 10;
    static readonly WIDGETS_PER_SERVICE_LIMIT = 100;
    static readonly WIDGETS_PER_GROUP_LIMIT = 4;
    static MAX_LAYERS_LIMIT = 4;
    static readonly REPORT_LIMIT = 7;
    static readonly BUDGET_LIMIT = 5;
    static readonly ALERT_LIMIT = 30;
    static readonly RESOURCE_SELECTION_LIMIT = 40;
    static readonly RESOURCE_SELECTION_LIMIT_V2 = 100;
    static readonly CHECKS_SELECTION_LIMIT_V2 = 100;
    static readonly RIGHTSIZE_SELECTION_LIMIT_V2 = 5;
    static readonly GMAIL_CLIENT_ID =
        '581491846642-vdn2p5qdebqirsd0ep644gntb4ms8n90.apps.googleusercontent.com';
    static readonly O365_CLIENT_ID = '013b56aa-e384-46e2-a6e9-119a8209ad09';
    static readonly SLACK_CLIENT_ID = '237994180931.586405705907';
    static readonly ZOHO_CLIENT_ID = '1000.K3K9RRXBX7D620IR4NCXWRIUIPSO5K';
    static PREVIEW_MODE: boolean = false;
    static pseudoConsoleConfig: any = null;

    static readonly VAPID_KEYS = {
        publicKey: 'can be assigned later ....',
        privateKey: 'can be assigned later ....',
    };
    static readonly CUSTOM_WIDGET_LIMIT = 100;
    static readonly WIDGET_DOCUMENTATION_LIMIT = 100;
    static readonly CUSTOMER_CATALOG_DISABLED_FOR = ['root_redingtongulf'];
    static readonly CUSTOMER_TO_SHOW_SIGNUP = [
        'portal.issi-inc.com',
        'bluecloud.click',
        'sandeep02.click',
        'sandeep.centilytics.com',
        'atlas-dev.centilytics.com',
        'stagel1.click',
        'stage-v1.click',
        'atlas-staging.centilytics.com',
        'stagetestwhitelabel.click',
        'stagewhitelabeltest.click',
        'onedevwhitelabel.click',
        'assessment-dev.centilytics.com',
        'assessment.onedevwhitelabel.click',
    ];
    static readonly CUSTOMER_TO_SHOW_PRIVACY_POILICY_UPDATED_MESSAGE = [
        'portal.issi-inc.com',
        'bluecloud.click',
        'sandeep02.click',
        'sandeep.centilytics.com',
        'stagel1.click',
        'stage-v1.click',
        'stagetestwhitelabel.click',
        'stagewhitelabeltest.click',
        'onedevwhitelabel.click',
        'assessment-dev.centilytics.com',
        'assessment.onedevwhitelabel.click',
    ];
}
