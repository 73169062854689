import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { SubWidgetInjectedData } from 'src/app/shared/classes/SubWidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FileType } from 'src/app/shared/enums/FileType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { PseudoConsolePreviewSourceTypes } from 'src/app/shared/enums/PreviewModeSourceType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';

const FileTypesToAccept = [
    FileType.PDF,
    FileType.HTML,
    FileType.MS_WORD_DOC,
    FileType.MS_WORD_DOCS
];

@Component({
    selector: 'app-whitelebeling-terms-of-services',
    templateUrl: './whitelebeling-terms-of-services.component.html',
    styleUrls: ['./whitelebeling-terms-of-services.component.sass']
})
export class WhitelebelingTermsOfServicesComponent implements OnInit {
    excelFormInput: IFormGeneratorInput = null;
    excelForm: FormGroup;
    resetObservable: Subject<string>;
    resetSubscription: Subscription;
    subWidgetId: string;
    reInitializeWhiteLabelData: Function;
    save: IButtonGeneratorInput = {
        buttonName: 'Save As Draft',
        buttonType: ButtonType.FLAT,
        buttonColorType: ButtonColorType.PRIMARY,
        showLoader: true,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Cannot perform this action in preview mode'
            : null,
        function: (buttonRef) => {
            this.saveConfiguration(buttonRef);
        }
    };

    preview: IButtonGeneratorInput = {
        buttonName: 'Preview',
        buttonType: ButtonType.FLAT,
        disable: GlobalConfiguration.PREVIEW_MODE,
        hoverText: GlobalConfiguration.PREVIEW_MODE
            ? 'Already in preview mode'
            : null,
        buttonColorType: ButtonColorType.SECONDARY,
        function: () => {
            if (this.subWidgetData.data.previewWhitelabelModalData) {
                const data: any = Helper.cloneDeep(
                    this.subWidgetData.data.whiteLabelingData
                );
                const draftEmailConfig = Helper.cloneDeep(
                    this.subWidgetData.data.whiteLabelingDraft
                );
                draftEmailConfig['companyName'] =
                    draftEmailConfig['companyName'] ?? null;
                data['emailConfig'] = data['emailConfig'] ?? draftEmailConfig['emailConfig'];
                data['themeConfig'] = data['themeConfig'] ?? draftEmailConfig['themeConfig'];
                this.subWidgetData.data.previewWhitelabelModalData(data, {
                    previewSource:
                        PseudoConsolePreviewSourceTypes.PREVIEW_SOURCE_TERMS_OF_SERVICE,
                    allowAccessToStorageKeys: false,
                    shouldHideTerms: this.termsOfServiceHideFieldValue
                });
            }
        }
    };
    whiteLabelingData;
    widgetRef;
    tempMail: any;
    private termsOfServiceHideFieldValue: boolean;

    constructor(
        private subWidgetData: SubWidgetInjectedData,
        public modalService: ModalService,
        public globalDataService: GlobalDataService,
        public notificationsService: NotificationsService,
        private widgetHttpService: WidgetHttpService
    ) {
        this.resetObservable = subWidgetData.resetObserver;
        this.subWidgetId = subWidgetData.subWidgetId;
        this.reInitializeWhiteLabelData =
            subWidgetData.data.reInitializeWhiteLabelData;
        this.whiteLabelingData = subWidgetData.data.whiteLabelingData;
        this.widgetRef = subWidgetData.parentWidgetRef;
    }

    ngOnInit(): void {
        this.initFormGenInput(false);
    }
    onFormValueChange(event) {
        if (event['excelFile']) {
            const fileType = this.excelForm.value?.excelFile?.files?.[0]?.type;
            if (fileType && !this.validateFileType(fileType)) {
                Helper.showErrorMessage(
                    this.notificationsService,
                    true,
                    'The supported file formats are Word, PDF and HTML'
                );
                this.excelForm?.get('excelFile')?.reset();
                return;
            }
            this.tempMail = this.excelForm.value.excelFile;
        }
        if (event['acceptTerms']) {
            const fieldValue = this.excelForm.value.acceptTerms;
            this.termsOfServiceHideFieldValue = fieldValue === 'hide';
        }
    }

    private validateFileType(fileType: string) {
        return FileTypesToAccept.find((t) => t === fileType);
    }

    initFormGenInput(useDraft?: boolean) {
        this.excelFormInput = null;
        this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
        let termsOfService;
        let fileName: string;
        if (
            !useDraft &&
            this.whiteLabelingData &&
            this.whiteLabelingData.termsOfServiceConfig
        ) {
            termsOfService = Helper.cloneDeep(
                this.whiteLabelingData.termsOfServiceConfig
            );
            this.subWidgetData.data.whiteLabelingDraft.termsOfServiceConfig =
                Helper.cloneDeep(termsOfService);
        } else {
            termsOfService =
                this.subWidgetData.data.whiteLabelingDraft.termsOfServiceConfig;
        }
        if (termsOfService?.fileName) {
            fileName = termsOfService?.fileName;
        }

        this.excelFormInput = {
            formName: 'Excel Upload',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Upload Terms of Services',
                    fieldType: FilterType.FILE,
                    required: false,
                    accept: FileTypesToAccept.join(','),
                    showLabel: true,
                    suffixIcon: {
                        iconData: {
                            type: IconType.SVG,
                            class: 'upload_icon',
                            extraClass: 'upload-icon'
                        },
                        hoverText: ''
                    },
                    fieldStyle: {
                        width: '80%',
                        padding: '5px 0'
                    },
                    appearance: 'legacy',
                    placeholder: fileName || 'Upload File',
                    name: 'excelFile'
                },
                {
                    label: 'Acceptance of Terms of Services',
                    fieldType: FilterType.RADIO,
                    required: false,
                    showLabel: true,
                    fieldStyle: {
                        width: '80%'
                    },
                    value: termsOfService?.hidden ? 'hide' : 'show',
                    listData: [
                        {
                            id: 'show',
                            label: 'Show'
                        },
                        {
                            id: 'hide',
                            label: 'Hide'
                        }
                    ],
                    appearance: 'legacy',
                    placeholder: '',
                    name: 'acceptTerms'
                }
            ]
        };
    }
    saveConfiguration(buttonRef) {
        if (this.subWidgetData.data.saveAsDraftApiInfo) {
            buttonRef.loader = true;
            const errorCallback = (err: any) => {
                buttonRef.loader = false;
                this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
                Helper.showErrorMessage(
                    this.notificationsService,
                    err,
                    'Error in saving Terms of Services Configuration'
                );
            };
            const fileObj: File = this.tempMail?.files?.[0];
            if (fileObj) {
                const fileName: string = fileObj.name;
                let key: string;
                this.getKeyFromBackend(
                    `terms-of-service.${
                        fileName.split('.')[fileName.split('.').length - 1]
                    }`,
                    (result: any) => {
                        key = result?.key;
                        this.dumpFileToS3(
                            result?.preSignedUrl,
                            fileObj,
                            () =>
                                this.sendDraftApi(
                                    errorCallback,
                                    buttonRef,
                                    key,
                                    fileName
                                ),
                            errorCallback
                        );
                    },
                    errorCallback
                );
            } else {
                this.sendDraftApi(errorCallback, buttonRef);
            }
        } else {
            buttonRef.loader = false;
        }
    }

    private getKeyFromBackend(
        fileName: string,
        onSuccess: Function,
        onError: Function
    ): void {
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_DATA_TO_S3_WHITELABEL.replace('{name}', fileName),
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: onSuccess,
            errorFunction: onError
        };
        apiArgs.intactUrl = apiArgs.url;
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    private dumpFileToS3(
        preSignedUrl: string,
        fileObj: File,
        onSuccess: Function,
        onError: Function
    ) {
        const apiArgs: IHitApi = {
            url: preSignedUrl,
            input: fileObj,
            requestType: RequestType.PUT,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                defaultHeaders: {
                    'Content-Type': fileObj.type
                }
            },
            function: onSuccess,
            errorFunction: onError
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    private sendDraftApi(
        errorCallback: Function,
        buttonRef: any,
        key?: string,
        fileName?: string
    ) {
        const draftToSend = Helper.cloneDeep(
            this.subWidgetData.data.whiteLabelingDraft
        );

        if (this.whiteLabelingData.privacyPolicyConfig) {
            // keeping other data preset
            draftToSend['privacyPolicyConfig'] = Helper.cloneDeep(
                this.whiteLabelingData.privacyPolicyConfig
            );
        }

        draftToSend['termsOfServiceConfig'] = {
            termsOfServiceKey:
                key ||
                this.subWidgetData.data.whiteLabelingData[
                    'termsOfServiceConfig'
                ]?.termsOfServiceKey,
            fileName:
                fileName ||
                this.subWidgetData.data.whiteLabelingData[
                    'termsOfServiceConfig'
                ]?.fileName,
            hidden: this.termsOfServiceHideFieldValue
        };
        const apiConfig = Helper.generateHitApiConfig(
            this.subWidgetData.data.saveAsDraftApiInfo
        );
        apiConfig.input = draftToSend;
        apiConfig.function = () => {
            buttonRef.loader = false;
            this.subWidgetData.data.whiteLabelingDraft.termsOfServiceConfig =
                Helper.cloneDeep(draftToSend?.termsOfServiceConfig);
            this.subWidgetData.parentWidgetRef.notificationsService.showSnackBar(
                'Configuration saved successfully. Click on publish to deploy these changes.',
                false,
                '',
                { duration: 3500 }
            );
            if (this.reInitializeWhiteLabelData) {
                this.reInitializeWhiteLabelData();
            }
            this.initFormGenInput(true);
            this.subWidgetData.parentWidgetRef.changeDetectorRef.detectChanges();
        };
        apiConfig.errorFunction = errorCallback;
        new HitApi(
            apiConfig,
            this.subWidgetData.parentWidgetRef.httpService,
            this.subWidgetData.parentWidgetRef.ngZone
        ).hitApi();
    }
}
