import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IInfoModal } from 'src/app/shared/interfaces/modal/IInfoModal';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IButtonGeneratorInput } from './../../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-sso-integration-step-two',
    templateUrl: './sso-integration-step-two.component.html',
    styleUrls: ['./sso-integration-step-two.component.sass']
})
export class SsoIntegrationStepTwoComponent implements OnInit {
    formGenInputs: IFormGeneratorInput[] = null;
    formGroups: Map<Symbol, FormGroup> = new Map();
    response;
    apiResponse;
    widgetRef: Widget;
    isIntegrated = false;
    edit: boolean;
    min1RoleReq = true;
    buttonInput: IButtonGeneratorInput[];
    addFieldButtonInput: IButtonGeneratorInput;
    deleteButtonInput: IButtonGeneratorInput;
    private viewData: any[] = [];   
    infoData: IInfoModal[] = [
        {
            infoHeading: '',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        'Here you have to map the roles (present in your active directory) to the views. The user with the role name you enter will inherit the views you select.'
                    ]
                }
            ]
        },
        {
            infoHeading: 'Note:',
            content: [
                {
                    type: 'PARAGRAPH',
                    data: [
                        "<i>This is an optional step applicable only in case you're using Role Based Access Control</i>"
                    ]
                }
            ]
        }
    ];
    constructor(
        public multiStepFormService: MultiStepFormService,
        public modalInputData: ModalInjectedData,
        private notificationsService: NotificationsService,
        private httpService: HttpService,
        private listHttpService: ListHttpService
    ) {
        this.response = modalInputData.data['response'];
        this.widgetRef = modalInputData.data['widgetRef'];
        this.edit = modalInputData.data['edit'];
        this.widgetRef.modalService.resetModal.subscribe(() => {
            this.formGenInputs = [];
            this.formGroups.clear();
            if (this.response && this.response['userRoleRequest']) {
                this.generateFormGenInputs(this.response['userRoleRequest']);
            } else {
                this.generateFormGenInputs([]);
            }
        });
    }

    ngOnInit(): void {
        if (this.response && this.response['userRoleRequest']) {
            this.generateFormGenInputs(this.response['userRoleRequest']);
        } else {
            this.generateFormGenInputs([]);
        }
        this.addFieldButtonInput = {
            buttonName: 'Add',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.RAISED,

            function: () => {},
            customClass: 'add-key-value'
        };
        this.deleteButtonInput = {
            buttonName: 'Delete',
            buttonType: ButtonType.STROKED,
            buttonColorType: ButtonColorType.WARN,
            function: () => {}
        };
        this.buttonInput = [
            {
                buttonName: 'Back',
                buttonType: ButtonType.STROKED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: () => {
                    this.multiStepFormService.previousStep(
                        this.modalInputData.modalId
                    );
                },
                customClass: 'back-button'
            },
            {
                buttonName: 'Save',
                buttonType: ButtonType.RAISED,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.integratedSso(buttonRef);
                }
            }
        ];
    }

    getFormGenInput(roleObject): IFormGeneratorInput {
        return {
            formId: Symbol(),
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    name: 'roleName',
                    fieldType: FilterType.TEXT,
                    label: 'Role Value',
                    placeholder: 'Enter Role Value',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: roleObject['roleName'],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Role value is required'
                        }
                    ]
                },
                {
                    name: 'viewList',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'View',
                    placeholder: 'Select View',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: roleObject['viewList'],
                    apiInfo: this.listHttpService.userListViewsApiInfo,
                    showKey: 'name',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'View is required'
                        }
                    ],
                    afterResponse: (response) => {
                        this.viewData = response;
                    }
                },
                {
                    name: 'defaultView',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Default View',
                    placeholder: 'Default View',
                    required: true,
                    showLabel: true,
                    appearance: 'legacy',
                    value: roleObject['defaultView'],
                    populateFromControl: 'viewList',
                    showKey: 'name',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Default View is required'
                        }
                    ],
                    listData: this.viewData
                }
            ],
            extraClass: 'integration-styling'
        };
    }
    lastFormUpdate(index: number, formId: Symbol) {
        if (this.formGroups.has(formId)) {
            const fg = this.formGroups.get(formId);
            if (fg.valid) {
                if (this.formGenInputs && this.formGenInputs.length) {
                    if (
                        this.getRoleNames().includes(fg.get('roleName').value)
                    ) {
                        this.notificationsService.showSnackBar(
                            'Role value already exists',
                            true
                        );
                    } else {
                        this.formGenInputs.unshift(this.getFormGenInput({}));
                        this.idleForms();
                    }
                }
            }
        }
    }
    idleForms() {
        this.formGenInputs = this.formGenInputs.map((fg, index) => {
            if (index !== 0) {
                fg.state = FormState.IDLE;
            }
            return Helper.dereference(fg);
        });
        this.widgetRef.changeDetectorRef.detectChanges();
    }
    getRoleNames(): number[] {
        const roles = [];
        this.formGenInputs.forEach((fg, index) => {
            if (index !== 0) {
                if (this.formGroups.has(fg.formId)) {
                    roles.push(
                        this.formGroups.get(fg.formId).get('roleName').value
                    );
                }
            }
        });
        return roles;
    }
    deleteFormGroup(index: number, formId: Symbol) {
        this.formGroups.delete(formId);
        this.formGenInputs = Helper.removeIndexfromArray(
            index,
            this.formGenInputs
        );
    }
    generateFormGenInputs(rolesData) {
        const fgInputs: IFormGeneratorInput[] = [];
        rolesData.forEach((data) => {
            const fInput = this.getFormGenInput(data);
            fInput.state = FormState.IDLE;
            fgInputs.push(fInput);
        });
        fgInputs.push(this.getFormGenInput({}));
        this.formGenInputs = fgInputs;
        if (this.formGenInputs.length > 1) {
            this.formGenInputs = this.formGenInputs.reverse();
        }
    }

    getRoleInputList() {
        const roles = [];
        this.formGenInputs.forEach((fg, index) => {
            if (index !== 0) {
                if (this.formGroups.has(fg.formId)) {
                    roles.push(this.formGroups.get(fg.formId).value);
                }
            }
        });
        return roles;
    }

    integratedSso(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        const input = Helper.cloneDeep(
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1).stepData
        );
        this.min1RoleReq =
            input['roleAttribute'] && input['roleAttribute'].length
                ? true
                : false;
        if (this.min1RoleReq && this.formGroups.size === 1) {
            this.notificationsService.showSnackBar(
                `Add atleast one role`,
                true
            );
            return;
        }
        input['userRoleRequest'] = this.getRoleInputList();
        delete input['metaDataInputType'];

        buttonRef.loader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );

        if (this.edit) {
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace(
                '{saml-integration-id}',
                this.response['id']
            );
        }
        apiArgs.input = input;
        apiArgs.function = (response) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.widgetRef.notificationsService.showSnackBar(
                this.response
                    ? 'SAML Updated Successfully.'
                    : 'SAML Integrated Successfully.'
            );
            this.widgetRef.refreshWidget();
            this.widgetRef.modalService.closeModal(
                null,
                this.modalInputData.modalId
            );
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error performing action'
            );
        };

        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
