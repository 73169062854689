import { HttpResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { environment } from 'src/environments/environment';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { HttpService } from '../services/http/http-main/http.service';
import { IotHandler } from './IotHandler';
import { Logger } from './Logger';

export class HitApi {
    arguments: IHitApi;
    urlChanged = false;
    refreshCount: number = 0;
    retryCount: number = 1;
    readonly BYPASS_MODULES = [
        ApiUrls.VIEW_AND_API_MGMNT_NAME,
        ApiUrls.IDENTITY_ACCESS_MGMNT_NAME
    ];
    readonly SKIP_API = [
        ApiUrls.KUBERNETES_JOBS_API,
        ApiUrls.GENERAL_CONFIGURATION,
        ApiUrls.GET_CONFIGURATION,
        ApiUrls.FRAMEWORK_STATUS_API_V2,
        ApiUrls.CREATE_FRAMEWORK,
        ApiUrls.GET_COMMENT_URL,
        ApiUrls.GET_TECHNICAL_DESCRIPTION,
        ApiUrls.DELETE_COMMENT_API,
        ApiUrls.EDIT_COMMENT,
        ApiUrls.ADD_DATA_TO_S3,
        ApiUrls.GET_STATUS,
        ApiUrls.GET_WIDGETS_FRAMEWORK,
        ApiUrls.GET_TAGS_FRAMEWORK,
        // ApiUrls.GOOGLE_SIGN_UP,
        // ApiUrls.ANAPLAN_SIGN_UP
    ];
    topicDataMap = new Map<string, number>();
    timeoutToken: ReturnType<typeof setTimeout>;
    constructor(
        args: IHitApi,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {
        this.arguments = args;
    }

    /**
     * @param isLogApi Boolean used to check if the API we are hitting is a logging API.
     */

    hitApi(isLogApi = false, responseSub?: Subject<any>): Subject<any> {
        this.arguments.url =
            (this.arguments.config && this.arguments.config.ignoreBaseUrl) ||
            this.arguments.url.includes('http')
                ? this.arguments.url
                : `${this.httpService.baseUrl}${
                      this.arguments.url[0] === '/'
                          ? this.arguments.url
                          : `/${this.arguments.url}`
                  }`;

        // Url Change Handling
        if (
            !this.urlChanged &&
            !environment.baseUrl.includes('apistaging') &&
            !environment.baseUrl.includes('api-dev') &&
            !environment.baseUrl.includes('api-assessment-dev') &&
            this.arguments.config.skipBypassRegion !== true &&
            this.BYPASS_MODULES.reduce((prevValue, currentValue) => {
                if (
                    prevValue === 1 ||
                    this.arguments.url?.includes(currentValue)
                ) {
                    return 1;
                }
                return 0;
            }, 0)
        ) {
            const REGION =
                this.arguments.config.masterRegion &&
                !this.arguments.config.iotRegion
                    ? this.arguments.config.masterRegion
                    : this.httpService.iotConfig.region;
            if (!REGION) {
                return;
            }
            this.arguments.url = this.arguments.url.replace('v2', REGION);
            this.urlChanged = true;
        }
        if (this.arguments.mockApi && environment.mock) {
            // Adding mockapi=y in header
            const defaultHeaders = {
                ...this.arguments.config.defaultHeaders,
                mockapi: 'y'
            };
            this.arguments.config.defaultHeaders = { ...defaultHeaders };
        }
        let responseSubject: Subject<any> = new Subject();
        if (responseSub) {
            responseSubject = responseSub;
        }
        const time = moment().millisecond();
        this.arguments.config.time = time;

        const logData = {
            startTime: moment(),
            endTime: null,
            timeDifference: null,
            requestData: {
                url: this.arguments.url,
                requestType: this.arguments.requestType,
                authorization: this.arguments.config.authorization,
                inputData: this.arguments.input,
                response: null,
                iotResponse: null,
                headers: this.arguments.config.defaultHeaders,
                websiteUrl: window.location.href
            },
            jobId: null,
            jobIdResponseTime: null,
            iotResponseTimeDifference: null,
            errorResponse: null,
            token: this.httpService.bearerToken
        };
        const captureApiEndTime = () => {
            logData.endTime = moment();
            logData.timeDifference = logData.endTime.diff(logData.startTime);
        };

        if (this.httpService.apiTimeMapper[this.arguments.uniqueIdentity]) {
            this.httpService.apiTimeMapper[this.arguments.uniqueIdentity].time =
                time;
        } else {
            this.httpService.apiTimeMapper[this.arguments.uniqueIdentity] = {};
            this.httpService.apiTimeMapper[this.arguments.uniqueIdentity].time =
                time;
        }

        this.arguments.config.defaultHeaders = {
            ...this.arguments.config.defaultHeaders,
            'api-route-suffix': this.arguments.intactUrl ?? this.arguments.url
        };

        const subscription = this.httpService
            .hitApi(
                this.arguments.url,
                this.arguments.requestType,
                this.arguments.input,
                this.arguments.config
            )
            .pipe(take(1))
            .subscribe(
                (httpResponse: HttpResponse<any>) => {
                    captureApiEndTime();
                    const handleResponse = (response, headers?) => {
                        clearTimeout(this.timeoutToken);
                        logData.requestData.response = response;
                        if (
                            response &&
                            this.httpService.apiTimeMapper[
                                this.arguments.uniqueIdentity
                            ] &&
                            time ===
                                this.httpService.apiTimeMapper[
                                    this.arguments.uniqueIdentity
                                ].time
                        ) {
                            const handleFunctionCalls = (res, headers?) => {
                                if (
                                    this.arguments.config &&
                                    this.arguments.config.sendOnlyResponse
                                ) {
                                    responseSubject.next(res);
                                } else if (this.arguments.function) {
                                    this.arguments.function(
                                        res,
                                        this.arguments.config.extraData,
                                        headers
                                    );
                                }
                                if (this.arguments.endFunction) {
                                    this.arguments.endFunction();
                                }
                            };

                            // if payload is large
                            if (response['referS3UrlBydefault']) {
                                this.httpService.getDataFromS3(
                                    response['self'],
                                    (s3Res) => {
                                        handleFunctionCalls(s3Res);
                                    }
                                );
                                return;
                            }

                            if (response['jobId']) {
                                Logger.codeLog('Data coming from IOT .....');

                                // Log jobId
                                logData.jobId = response['jobId'];

                                this.httpService.jobIdConfigMapper[
                                    response['jobId']
                                ] = this.arguments;
                                const iotHandler = new IotHandler(
                                    this.httpService,
                                    this.ngZone
                                );
                                iotHandler.iotConfig = this.arguments;
                                iotHandler.iotConfig.config.responseSub =
                                    responseSubject;

                                if (!isLogApi) {
                                    this.hitLogsApi(true, logData);
                                }
                                if (response && response['message']) {
                                    this.arguments.function(response);
                                    iotResponseCallBack.bind(this);
                                } else {
                                    // Adding this callback for logging IOT Response data.
                                    iotResponseCallBack.bind(this);
                                }
                                function iotResponseCallBack() {
                                    iotHandler.iotConfig.config.iotReponseCallback =
                                        (response) => {
                                            // Log IOT response
                                            logData.jobIdResponseTime =
                                                moment();
                                            logData.requestData.iotResponse =
                                                response;
                                            logData.iotResponseTimeDifference =
                                                logData.jobIdResponseTime.diff(
                                                    logData.startTime
                                                );
                                            if (!isLogApi) {
                                                this.hitLogsApi(true, logData);
                                            }
                                        };
                                }
                                iotHandler.listner();
                                return;
                            }
                            if (this.arguments.config.isCached) {
                                // Caching response
                                this.httpService.apiResponseCacheService.store(
                                    this.arguments.url,
                                    {
                                        input: this.arguments.input,
                                        output: response
                                    }
                                );
                            }

                            handleFunctionCalls(response, headers);
                        } else if (!response && httpResponse) {
                            this.arguments.function(httpResponse);
                        } else if (!response) {
                            this.arguments.function(null);
                        }
                    };

                    if (!isLogApi) {
                        this.hitLogsApi(false, logData);
                    }

                    let response = httpResponse.body
                        ? httpResponse.body
                        : httpResponse;
                    const headers = httpResponse.headers
                        ? httpResponse.headers
                        : httpResponse;
                    if (
                        (httpResponse.status === 200 ||
                            httpResponse.status === 201) &&
                        !response
                    ) {
                        response = {
                            status: true
                        };
                    }
                    if (
                        response instanceof Blob &&
                        response.type &&
                        response.type === 'application/json'
                    ) {
                        const fr = new FileReader();
                        fr.onload = (e) => {
                            if (
                                e &&
                                e.target &&
                                typeof e.target.result === 'string'
                            ) {
                                try {
                                    handleResponse(
                                        JSON.parse(e.target.result),
                                        headers
                                    );
                                } catch (err) {
                                    handleResponse(response, headers);
                                }
                            }
                        };

                        fr.readAsText(response);
                        return;
                    }

                    handleResponse(response, headers);
                },
                (error) => {
                    const includesIn = this.SKIP_API.reduce(
                        (prevValue, currentValue) => {
                            if (
                                prevValue === 1 ||
                                this.arguments.url?.includes(currentValue)
                            ) {
                                return 1;
                            }
                            return 0;
                        },
                        0
                    );
                    if (
                        error.status !== 502 ||
                        this.retryCount > 5 ||
                        includesIn
                    ) {
                        if (this.urlChanged && this.refreshCount < 1) {
                            this.arguments.config.ignoreBaseUrl = true;
                            const REGION =
                                this.arguments.config.masterRegion &&
                                !this.arguments.config.iotRegion
                                    ? this.arguments.config.masterRegion
                                    : this.httpService.iotConfig.region;
                            this.arguments.url = this.arguments.url.replace(
                                REGION,
                                'v2'
                            );
                            this.refreshCount++;
                            this.hitApi(isLogApi, responseSubject);
                        } else if (this.refreshCount === 1) {
                            this.urlChanged = false;
                        }

                        if (!this.urlChanged && this.arguments.errorFunction) {
                            this.arguments.errorFunction(error);
                        }
                        if (!this.urlChanged && this.arguments.errorMessage) {
                            if (
                                'messages' in error.error &&
                                Array.isArray(error.error.messages)
                            ) {
                                this.arguments.errorMessage.next(
                                    error.error.messages[0]['message']
                                );
                            } else {
                                this.arguments.errorMessage.next(
                                    error.error.message
                                );
                            }
                        }
                        if (!this.urlChanged && this.arguments.endFunction) {
                            this.arguments.endFunction();
                        }
                        captureApiEndTime();
                        logData.errorResponse = error;
                        if (!isLogApi) {
                            this.hitLogsApi(false, logData);
                        }
                    } else {
                        // Retry for Erro code = 502
                        if (error.status === 502) {
                            this.timeoutToken = setTimeout(() => {
                                if (this.retryCount < 6) {
                                    this.hitApi(false);
                                    this.retryCount++;
                                }
                            }, 10000);
                        }
                    }
                }
            );

        if (this.arguments.returnApiCallSubscription) {
            this.arguments.returnApiCallSubscription(subscription);
        }
        return responseSubject;
    }

    hitIotStreamApi() {
        let client = null;
        this.arguments.iotStreamData.iotInput.dataReceivedCallback = (
            response,
            totalValueCount,
            recivedDataCount
        ) => {
            if (totalValueCount)
                this.topicDataMap.set(
                    this.arguments.iotStreamData.iotInput.topic,
                    totalValueCount
                );

            let finalCount;
            if (recivedDataCount !== undefined) {
                finalCount = recivedDataCount;
            }

            if (
                this.topicDataMap.size > 0 &&
                this.topicDataMap.get(
                    this.arguments.iotStreamData.iotInput.topic
                ) &&
                finalCount ===
                    this.topicDataMap.get(
                        this.arguments.iotStreamData.iotInput.topic
                    )
            ) {
                this.arguments.partialBindFunction(response);
                this.arguments.partialBindFunction({
                    status: 'CLOSED'
                });
                if (this.arguments.streamCloseCallback) {
                    this.arguments.streamCloseCallback();
                }

                // CLOSE CONNECTION
                if (client !== null) {
                    client.unsubscribe(
                        this.arguments.iotStreamData.iotInput.topic
                    );
                    client.disconnect();
                }
            } else {
                this.arguments.partialBindFunction(response);
            }
        };

        this.arguments.iotStreamData.iotInput.connectionFailureCallback = (
            response
        ) => {
            if (this.arguments.streamCloseCallback) {
                this.arguments.streamCloseCallback();
            }
            this.arguments?.errorFunction('IOT Connection not successful');
        };
        this.arguments.iotStreamData.iotInput.connectionLostCallback = (
            response
        ) => {
            if (this.arguments.streamCloseCallback) {
                this.arguments.streamCloseCallback();
            }
            this.arguments.errorFunction('IOT Connection lost');
        };

        client = this.httpService.hitIotStream(
            this.arguments.iotStreamData.iotInput
        );
    }

    /**
     * Function is responsbile for hitting the API for logging all the request and responses in our project.
     * @param isJobId Boolean for any API whose response is coming from IOT.
     * @param inputData Payload which we are logging
     */

    hitLogsApi(isJobId: boolean, inputData: any): any {
        this.httpService.hitLogApi(
            this.ngZone,
            isJobId,
            inputData,
            false,
            this.arguments.url
        );
    }
}
