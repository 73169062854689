import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IDropdownData } from 'src/app/shared/interfaces/dropdown-data/IDropdownData';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { ConfirmationModalComponent } from '../../../modal-templates/confirmation-modal/confirmation-modal.component';
import { CreateUserStepOneComponent } from '../../../modal-templates/create-user/create-user-step-one/create-user-step-one.component';
import { FormGeneratorModalComponent } from '../../../modal-templates/form-generator-modal/form-generator-modal.component';
import { MultiButtonGeneratorComponent } from '../../../multi-button-generator/multi-button-generator.component';
import { IAction } from './../../../../interfaces/actions/IAction';
import { CreateUserStepTwoComponent } from './../../../modal-templates/create-user/create-user-step-two/create-user-step-two/create-user-step-two.component';

@Component({
    selector: 'app-users-listing',
    templateUrl: './users-listing.component.html',
    styleUrls: ['./users-listing.component.sass']
})
export class UsersListingComponent implements OnInit {
    widgetRef: Widget;
    tableInput: ITableGeneratorInput;

    agGrid: GridOptions;

    formGenInput: IFormGeneratorInput = null;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private listHttpService: ListHttpService,
        private userDataCacheService: UserDataCacheService
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
        this.tableInput = {
            afterResponse: (response: any) => {
                if (response && response.length) {
                    const operationalActions = new Map<string, IAction[]>();
                    operationalActions.set('Bulk Action', [
                        {
                            message: '',
                            state: ActionState.ENABLED,
                            visibility: ActionVisibility.VISIBLE,
                            actionId: 'user_view_update',
                            icon: {
                                type: IconType.SVG,
                                class: 'circle_check',
                                text: 'Bulk Update',
                                extraClass: 'inline-fix-box-1'
                            },
                            function: (actionId) => {
                                if (this.agGrid) {
                                    const selectedRows =
                                        this.agGrid.api.getSelectedRows();
                                    if (selectedRows.length) {
                                        this.openBulkUpdateModal(selectedRows);
                                    } else {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Select atleast one user for updation'
                                        );
                                    }
                                }
                            }
                        }
                    ]);
                    this.widgetRef.operationalActions.next(operationalActions);
                }
            },
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-tie'
            },
            selection: 'multiple',
            buttons: [
                {
                    buttonName: 'Add User',
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonType: ButtonType.FLAT,
                    function: this.addUser.bind(this)
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            columns: [
                {
                    columnName: 'Name',
                    columnKey: 'name',
                    columnValueGetter: (params: ValueGetterParams) => {
                        const name = `${params.data['firstName'] ?? ''} ${
                            params.data['lastName'] ?? ''
                        }`;
                        return name;
                    }
                },
                {
                    columnName: 'Email',
                    columnKey: 'email'
                },
                {
                    columnName: 'Root',
                    columnKey: 'root'
                },
                {
                    columnName: 'Actions',
                    columnKey: 'actions',
                    pinned: 'right',
                    minWidth: 150,
                    filter: false,
                    sortable: false,
                    headerClass: 'grid-cell-data-centred',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        const root = rowData.data['root'];
                        const email = rowData.data['email'];
                        const isRoot =
                            root ||
                            (email &&
                                email === this.userDataCacheService.emailId);
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                disable: isRoot,
                                function: () => {
                                    // Edit user
                                    const userData = rowData['data'];
                                    const moreInfoIcon: IIcon = {
                                        type: IconType.FONTAWSOME,
                                        class: 'fas fa-user-plus'
                                    };

                                    const modalData: IModalData = {
                                        modalName: 'Update User',
                                        modalIcon: moreInfoIcon,
                                        sourceId: this.widgetRef.uniqueIdentity,
                                        modalType: ModalType.MIDDLE,
                                        modalHeightVh: 90,
                                        modalWidthVw: 70,
                                        modalSteps: [
                                            {
                                                stepData: {
                                                    componentToLoad:
                                                        CreateUserStepOneComponent,
                                                    payload: {
                                                        data: {
                                                            edit: true,
                                                            userData
                                                        }
                                                    }
                                                },
                                                stepName: 'USER INFORMATION'
                                            },
                                        ]
                                    };
                                    this.modalService.openModal(modalData);
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                disable: isRoot,
                                function: () => {
                                    if (isRoot) {
                                        return;
                                    }
                                    const modalData: IConfirmationModal = {
                                        modalName: 'Delete User',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-user-times'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to permanently delete user ?`,
                                        buttonText: 'Delete',
                                        buttonColorType: ButtonColorType.WARN,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            if (isRoot) {
                                                return;
                                            }

                                            const userData = rowData['data'];
                                            const apiConf: IHitApi = {
                                                url: `${ApiUrls.USERS_END_POINT}/${userData['id']}`,
                                                input: {},
                                                intactUrl: `${ApiUrls.USERS_END_POINT}/{id}`,
                                                config: {
                                                    authorization:
                                                        AuthorizationType.BEARER_TOKEN
                                                },
                                                requestType: RequestType.DELETE,
                                                uniqueIdentity: Symbol(),
                                                function: (response) => {
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        `${userData['firstName']} permanently deleted`
                                                    );
                                                    this.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    this.widgetRef.refreshWidget();
                                                },
                                                errorFunction: (error) => {
                                                    this.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                    Helper.showErrorMessage(
                                                        this.widgetRef
                                                            .notificationsService,
                                                        error,
                                                        `Error deleting ${userData['firstName']}`
                                                    );
                                                }
                                            };
                                            new HitApi(
                                                apiConf,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-around'
                            }
                        };
                        rowData['buttonGenInputs'] = buttons;
                        rowData['options'] = buttonOptions;
                        return rowData;
                    }
                },
                {
                    columnName: 'MFA',
                    columnKey: 'enableTwoFactorAuth',
                    cellRenderer: (rowData) => {
                        const slideOn = {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-toggle-on'
                        };
                        const slideOff = {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-toggle-off'
                        };

                        const twoFactorAuth = rowData.data[
                            'enableTwoFactorAuth'
                        ]
                            ? slideOn
                            : slideOff;

                        function callbackFn() {
                            const email = rowData.data['email'];
                            const rawRootData = rowData.data['root'];
                            const root = this.userDataCacheService.rootUser;
                            const mfaStatus =
                                rowData.data['enableTwoFactorAuth'];

                            if (rawRootData && root && !mfaStatus) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Use Root MFA widget to enable MFA.',
                                    true
                                );
                                return;
                            }
                            if (rawRootData && mfaStatus) {
                                const formGenInput: IFormGeneratorInput = {
                                    formName: '',
                                    state: FormState.CREATE,
                                    submitButton: {
                                        buttonName: 'Submit',
                                        showLoader: true,
                                        buttonColorType:
                                            ButtonColorType.PRIMARY,
                                        buttonType: ButtonType.FLAT,
                                        function: (
                                            buttonRef: IButtonGeneratorInput,
                                            formGroup: FormGroup,
                                            modalId: Symbol
                                        ) => {
                                            if (formGroup.valid) {
                                                buttonRef.loader = true;
                                                const apiConf: IHitApi = {
                                                    url: ApiUrls.DISABLE_ROOT_USER_MFA,
                                                    input: {
                                                        email: email,
                                                        password:
                                                            formGroup.get(
                                                                'password'
                                                            ).value
                                                    },
                                                    config: {
                                                        authorization:
                                                            AuthorizationType.BEARER_TOKEN
                                                    },
                                                    requestType:
                                                        RequestType.POST,
                                                    uniqueIdentity: Symbol(),
                                                    function: () => {
                                                        this.widgetRef.notificationsService.showSnackBar(
                                                            'MFA disabled successfully'
                                                        );
                                                        if (
                                                            rawRootData &&
                                                            root
                                                        ) {
                                                            this.userDataCacheService.mfaStatus =
                                                                false;
                                                        }

                                                        buttonRef.loader =
                                                            false;
                                                        this.widgetRef.refreshWidget();
                                                        this.widgetRef.modalService.closeModal(
                                                            null,
                                                            modalId
                                                        );
                                                    },
                                                    errorFunction: (error) => {
                                                        this.widgetRef.notificationsService.showSnackBar(
                                                            error.error.message,
                                                            true
                                                        );
                                                        buttonRef.loader =
                                                            false;
                                                        this.widgetRef.modalService.closeModal(
                                                            null,
                                                            modalId
                                                        );
                                                        this.widgetRef.refreshWidget();
                                                    }
                                                };

                                                new HitApi(
                                                    apiConf,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            }
                                        }
                                    },
                                    fields: [
                                        {
                                            label: 'Password',
                                            placeholder: 'Enter Password',
                                            name: 'password',
                                            fieldType: FilterType.PASSWORD,
                                            required: true,
                                            validations:
                                                Helper.getPasswordValidators()
                                        }
                                    ]
                                };
                                const modalArgs: IModalData = {
                                    modalName: 'Disable MFA',
                                    sourceId:
                                        this.widgetRef.widgetData
                                            .widgetUniqueIdentifier,
                                    modalType: ModalType.MIDDLE,
                                    modalIcon: {
                                        type: IconType.MATICON,
                                        class: 'verified_user'
                                    },
                                    modalSteps: [
                                        {
                                            stepName: '',
                                            stepData: {
                                                componentToLoad:
                                                    FormGeneratorModalComponent,
                                                payload: {
                                                    data: formGenInput
                                                }
                                            }
                                        }
                                    ],
                                    modalWidthVw: 50,
                                    modalHeightVh: 38
                                };
                                this.widgetRef.modalService.openModal(
                                    modalArgs
                                );
                                return;
                            }
                            if (!root) {
                                this.widgetRef.notificationsService.showSnackBar(
                                    "You can't perform this action.",
                                    true
                                );
                                return;
                            }

                            const confirmationModalData: IConfirmationModal = {
                                function: (modalId: Symbol) => {
                                    const apiConf: IHitApi = {
                                        url: mfaStatus
                                            ? ApiUrls.DISABLE_NON_ROOT_USER_MFA
                                            : ApiUrls.ENABLE_NON_ROOT_USER_MFA,
                                        input: { email: email },
                                        config: {
                                            authorization:
                                                AuthorizationType.BEARER_TOKEN
                                        },
                                        requestType: RequestType.POST,
                                        uniqueIdentity: Symbol(),
                                        function: () => {
                                            this.widgetRef.notificationsService.showSnackBar(
                                                `MFA ${
                                                    mfaStatus
                                                        ? 'disabled'
                                                        : 'enabled'
                                                } successfully.`
                                            );
                                            this.widgetRef.refreshWidget();
                                            this.widgetRef.modalService.closeModal(
                                                null,
                                                modalId
                                            );
                                        },
                                        errorFunction: (error) => {
                                            this.widgetRef.modalService.closeModal(
                                                null,
                                                modalId
                                            );
                                            Helper.showErrorMessage(
                                                this.widgetRef
                                                    .notificationsService,
                                                error,
                                                `Error while ${
                                                    mfaStatus
                                                        ? 'disabling'
                                                        : 'enabling'
                                                } MFA.`
                                            );
                                        }
                                    };

                                    new HitApi(
                                        apiConf,
                                        this.widgetRef.httpService,
                                        this.widgetRef.ngZone
                                    ).hitApi();
                                },

                                modalName: 'Multi Factor Authentication',
                                modalIcon: {
                                    type: IconType.MATICON,
                                    class: 'verified_user'
                                },
                                contextIcon: {
                                    extraClass: 'color-accent',
                                    type: IconType.FONTAWSOME,
                                    class: 'fas fa-exclamation-triangle'
                                },
                                confirmationMessage: `Are you sure you want to ${
                                    mfaStatus ? 'disable' : 'enable'
                                } MFA for this account ?`,
                                buttonText: mfaStatus ? 'Disable' : 'Enable',
                                buttonColorType: ButtonColorType.SECONDARY,
                                loaderOnExec: true
                            };
                            const modalData: IModalData = {
                                modalName: confirmationModalData.modalName,
                                modalIcon: confirmationModalData.modalIcon,
                                sourceId: Symbol(),
                                modalType: ModalType.MIDDLE,
                                modalHeightVh: 30,
                                modalWidthVw: 50,
                                modalSteps: [
                                    {
                                        stepData: {
                                            componentToLoad:
                                                ConfirmationModalComponent,
                                            payload: {
                                                data: {
                                                    function:
                                                        confirmationModalData.function,
                                                    params: confirmationModalData
                                                }
                                            }
                                        },
                                        stepName: 'Confirmation'
                                    }
                                ]
                            };
                            this.widgetRef.modalService.openModal(modalData);
                        }
                        return Helper.generateToggleButtons(
                            twoFactorAuth,
                            callbackFn.bind(this)
                        );
                    }
                }
            ]
        };
    }

    ngOnInit(): void {}

    addUser(buttonRef: IButtonGeneratorInput) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: 'fas fa-user-plus'
        };

        const modalData: IModalData = {
            modalName: 'Create User',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 90,
            modalWidthVw: 70,
            sourceId: this.widgetRef.uniqueIdentity,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateUserStepOneComponent,
                        payload: null
                    },
                    stepName: 'USER INFORMATION'
                },
                {
                    stepData: {
                        componentToLoad: CreateUserStepTwoComponent,
                        payload: null
                    },
                    stepName: 'PERMISSIONS'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    onSelectionChanged(event) {
        if (this.agGrid) {
            this.agGrid.api.getSelectedNodes().length
                ? this.widgetRef.showNotificationBadge.next(true)
                : this.widgetRef.showNotificationBadge.next(false);
        }
    }

    openBulkUpdateModal(selectedRows: any[]) {
        const userListData: IDropdownData[] = [];
        selectedRows.forEach((rowData) => {
            userListData.push({
                id: rowData['id'],
                label: `${rowData['firstName'] ? rowData['firstName'] : ''} ${
                    rowData['lastName'] ? rowData['lastName'] : ''
                }`,
                disabled: true
            });
        });
        const modalId = Symbol();
        const formGenInput: IFormGeneratorInput = {
            formName: 'Bulk Update View',
            state: FormState.EDIT,
            submitButton: {
                buttonName: 'Update',
                buttonType: ButtonType.FLAT,
                buttonColorType: ButtonColorType.PRIMARY,
                showLoader: true,
                function: (
                    buttonRef: IButtonGeneratorInput,
                    formGroup: FormGroup
                ) => {
                    let usersSelected = [];
                    let viewsSelected = [];
                    if (
                        formGroup.get('usersSelected') &&
                        formGroup.get('viewsSelected')
                    ) {
                        usersSelected = formGroup.get('usersSelected').value;
                        viewsSelected = formGroup.get('viewsSelected').value;
                    }
                    if (viewsSelected.length) {
                        if (usersSelected.length) {
                            usersSelected = usersSelected.map(
                                (user) => user['id']
                            );
                            this.bulkUpdateView(
                                usersSelected,
                                viewsSelected,
                                () => {
                                    this.modalService.closeModal(null, modalId);
                                },
                                () => {
                                    buttonRef.loader = false;
                                }
                            );
                        } else {
                            buttonRef.loader = false;
                            this.widgetRef.notificationsService.showSnackBar(
                                'No user selected'
                            );
                        }
                    } else {
                        buttonRef.loader = false;
                        this.widgetRef.notificationsService.showSnackBar(
                            'No view selected'
                        );
                    }
                }
            },
            fields: [
                {
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'Users Selected',
                    name: 'usersSelected',
                    placeholder: 'Select Users',
                    required: false,
                    appearance: 'legacy',
                    showLabel: true,
                    listData: userListData,
                    value: userListData,
                    hideSelectAllInDropdown: true
                },
                {
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    label: 'View',
                    name: 'viewsSelected',
                    placeholder: 'Select Views',
                    required: false,
                    appearance: 'legacy',
                    showLabel: true,
                    apiInfo: this.listHttpService.userListViewsApiInfo,
                    showKey: 'name'
                }
            ]
        };
        const modalData: IModalData = {
            modalIcon: null,
            modalName: 'Add View',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalId: modalId,
            modalWidthVw: 60,
            modalAutoHeight: true,
            modalSteps: [
                {
                    stepName: 'Add View',
                    stepData: {
                        componentToLoad: FormGeneratorModalComponent,
                        payload: {
                            data: formGenInput
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    bulkUpdateView(users, viewIds, successCallback?, errorCallback?) {
        const apiInfo = this.widgetRef.widgetData.widgetInfo['batchUpdate'];
        if (apiInfo) {
            const apiArgs: IHitApi = Helper.generateHitApiConfig(apiInfo);
            apiArgs.input = { users, viewIds };
            apiArgs.function = (response) => {
                if (successCallback) {
                    successCallback();
                }
                this.widgetRef.refreshWidget(true);
                this.widgetRef.notificationsService.showSnackBar(
                    'User(s) updated successfully'
                );
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'User(s) updation failed'
                );
                if (errorCallback) {
                    errorCallback();
                }
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
}
