import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { ModalInjectedData } from './../../../../classes/ModalInjectedData';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { IUpdateAction } from './../../../../interfaces/update-action/IUpdateAction';
import { MultiStepFormService } from './../../../../services/modal/multi-step-form/multi-step-form.service';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
    selector: 'app-create-customer-step-one-v2',
    templateUrl: './create-customer-step-one-v2.component.html',
    styleUrls: ['./create-customer-step-one-v2.component.sass'],
})
export class CreateCustomerStepOneV2Component implements OnInit {
    customerInfoFirstFormGenInput: IFormGeneratorInput = null;
    customerInfoFirstFormRef: FormGroup;
    customerInfoSecondFormGenInput: IFormGeneratorInput = null;
    customerInfoSecondFormRef: FormGroup;
    customerInfoThirdFormgenInput: IFormGeneratorInput = null;
    customerInfoThirdFormRef: FormGroup;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    stepData: any;
    domainIds = [];
    widgetRef: Widget;
    tagsToBeDeleted = [];
    emailIds = [];
    edit: boolean;
    resetModalData: any;
    selectedTagsData = [];
    availableTagsData = [];
    allRowsData = [];
    finalTagsData = [];
    tagsData;
    tagsAssigned;
    resetFirstForm = null;
    resetSecondForm = null;
    resetThirdForm = null;
    aclId;
    viewFormGenInput: IFormGeneratorInput = null;
    viewFormGroup: FormGroup;
    resetViewForm = null;
    checkBox: boolean;
    emailValidations = [];

    nextButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Next',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: (buttonRef) => {
            this.nextStep(buttonRef);
        },
    };

    updateControlView: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);

    constructor(
        private modalInputData: ModalInjectedData,
        private listHttpService: ListHttpService,
        private modalService: ModalService,
        private multiStepFormService: MultiStepFormService,
        private ngZone: NgZone,
        private notificationService: NotificationsService,
        private filtersService: FiltersService,
        private httpService: HttpService
    ) {
        this.domainIds = modalInputData.data['domainIds'];
        this.emailIds = modalInputData.data['emailIds'];
        this.edit = modalInputData.data['edit'];
        this.allRowsData = modalInputData.data.allRowsData;
        this.widgetRef = modalInputData.data['widgetRef'];

        this.allRowsData.forEach((tags) => {
            this.availableTagsData.push(tags);
        });
        this.availableTagsData = Helper.removeDuplicateObjetsFromArray(
            this.availableTagsData
        );
        this.emailValidations = [
            {
                validator: CustomValidators.required,
                errorMessage: 'Email is required',
            },
            {
                validator: CustomValidators.email,
                errorMessage: 'Email is invalid',
            },
            {
                validator: CustomValidators.controlValueNotIn(this.emailIds),
                errorMessage: 'EmailId already exists',
            },
        ];
    }

    ngOnInit(): void {
        if (this.edit) {
            this.stepData = this.modalInputData.data['stepData'];

            this.selectedTagsData = this.modalInputData.data.stepData['tags'];

            this.resetModalData = Helper.cloneDeep(this.stepData);
            this.nextButtonGenInput.buttonName = 'Update';
        }
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);

            this.selectedTagsData = this.stepData['tags'];
        }
        this.setUpBasics();

        this.modalService.resetModal.subscribe((data) => {
            const tagsData =
                this.resetModalData && this.edit
                    ? this.resetModalData['tags']
                    : [];

            this.selectedTagsData = [];
            this.stepData = null;
            this.selectedTagsData = tagsData;
            this.finalTagsData['tags'] = tagsData;
            this.widgetRef.changeDetectorRef.detectChanges();

            let formData;

            if (this.customerInfoFirstFormRef) {
                formData = {
                    domainId: this.resetModalData
                        ? this.resetModalData['domainId']
                        : null,
                    checkBox: this.resetModalData
                        ? this.resetModalData['allowDomainBasedUsers']
                        : false,
                    companyName: this.resetModalData
                        ? this.resetModalData['companyName']
                        : null,
                    dataSourceId: this.resetModalData
                        ? this.resetModalData['region']
                        : null,
                    priceType: this.resetModalData
                        ? this.resetModalData['priceType']
                        : [],
                    dataRetentionMonths: this.resetModalData
                        ? this.resetModalData['dataRetentionMonths']
                        : null,
                };
                this.resetFirstForm(formData);
            }
            if (this.customerInfoSecondFormRef) {
                formData = {
                    rootUserFirstName: this.resetModalData
                        ? this.resetModalData['rootUserFirstName']
                        : null,
                    rootUserLastName: this.resetModalData
                        ? this.resetModalData['rootUserLastName']
                        : null,
                    rootUserEmail: this.resetModalData
                        ? this.resetModalData['rootUserEmail']
                        : null,
                    phoneNumber: this.resetModalData
                        ? this.resetModalData['contact']
                        : null,
                    password: this.resetModalData
                        ? this.resetModalData['password']
                        : '',
                    confirmPassword: this.resetModalData
                        ? this.resetModalData['confirmPassword']
                        : null,
                };
                this.resetSecondForm(formData);
            }
            if (this.customerInfoThirdFormRef) {
                formData = {
                    aclId: this.resetModalData
                        ? this.resetModalData['defaultAclId']
                        : null,
                };
                this.resetThirdForm(formData);
            }
            if (this.viewFormGroup) {
                formData = {
                    selectViews: null,
                };
                this.resetViewForm(formData);
            }
        });
    }
    setUpBasics() {
        this.customerInfoFirstFormGenInput = {
            formName: 'Create Customer',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Domain ID',
                    placeholder: 'Enter the Domain ID',
                    name: 'domainId',
                    required: true,
                    showLabel: true,
                    value: this.stepData ? this.stepData['domainId'] : null,
                    appearance: 'legacy',
                    fieldType: FilterType.TEXT,
                    disabled: this.edit ? true : false,

                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Domain ID is required',
                        },
                        {
                            validator: CustomValidators.controlValueNotIn(
                                this.domainIds
                            ),
                            errorMessage: 'Domain ID already exits',
                        },
                        {
                            validator: CustomValidators.noSpecialCharacters,
                            errorMessage:
                                'Domain ID cannot contain special character',
                        },
                    ],
                    onChange: () => {
                        this.generateRootUserForm(
                            this.customerInfoFirstFormRef
                                ? this.customerInfoFirstFormRef.value[
                                      'checkBox'
                                  ]
                                : false
                        );
                    },
                },
                {
                    fieldType: FilterType.CHECKBOX,
                    label: 'Allow User from this Domain only',
                    name: 'checkBox',
                    extraClass: 'required-checkbox accent-checkbox',
                    placeholder: '',
                    showLabel: true,
                    appearance: 'legacy',
                    showKey: 'label',
                    value: this.stepData
                        ? this.stepData['allowDomainBasedUsers']
                            ? this.stepData['allowDomainBasedUsers']
                            : this.stepData['checkBox']
                            ? this.stepData['checkBox']
                            : false
                        : false,

                    required: false,
                },
                {
                    label: 'Company Name',
                    name: 'companyName',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Enter Company Name',
                    fieldType: FilterType.TEXT,
                    value: this.stepData ? this.stepData['companyName'] : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Company Name is required',
                        },
                    ],
                },
                {
                    label: 'Select Region',
                    name: 'dataSourceId',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Select Region',
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    apiInfo: this.listHttpService.cacRegionsApiInfo,
                    value: this.stepData
                        ? this.stepData.region
                            ? this.stepData.region
                            : this.stepData.dataSourceId
                            ? this.stepData.dataSourceId
                            : null
                        : null,
                    disabled: this.edit ? true : false,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Region is required',
                        },
                    ],
                },

                {
                    label: 'Select Price Type',
                    name: 'priceType',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Select Price Type',
                    fieldType: FilterType.DROPDOWN_MULTIPLE,
                    apiInfo: this.listHttpService.priceTypeApiInfo,
                    value: this.stepData ? this.stepData['priceType'] : [],
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Price Type is required',
                        },
                    ],
                },
                {
                    label: 'Retention Period',
                    name: 'dataRetentionMonths',
                    required: true,
                    appearance: 'legacy',
                    showLabel: true,
                    placeholder: 'Enter the  Retention Period',
                    fieldType: FilterType.NUMBER,
                    value: this.stepData
                        ? this.stepData['dataRetentionMonths']
                        : null,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Retention period is required',
                        },
                    ],
                },
            ],
        };
        this.generateRootUserForm();

        this.customerInfoThirdFormgenInput = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Select Acl',
                    placeholder: 'Select Acl',
                    appearance: 'legacy',
                    showLabel: true,
                    name: 'aclId',
                    apiInfo: this.listHttpService.listAclApiInfo,
                    showKey: 'name',

                    fieldType: FilterType.DROPDOWN_SINGLE,
                    required: true,
                    value: this.stepData
                        ? this.stepData.defaultAclId
                            ? this.stepData.defaultAclId
                            : this.stepData.aclId
                            ? this.stepData.aclId
                            : ''
                        : '',
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Acl is required',
                        },
                    ],
                },
            ],
        };
    }
    checkValue($event) {
        if ($event['checkBox'] !== this.checkBox) {
            this.checkBox = $event['checkBox'];

            this.generateRootUserForm(this.checkBox);
        }
    }
    addViewField(value) {
        if (value['aclId'] !== this.aclId) {
            this.aclId = value['aclId'];

            this.viewFormGenInput = {
                formName: 'Create Customer',
                state: FormState.EDIT,
                submitButton: null,
                fields: [
                    {
                        label: 'Select View',
                        placeholder:
                            'Select view that you want to replicate to the customer',
                        name: 'selectViews',
                        getKey: null,
                        showLabel: true,
                        appearance: 'legacy',
                        value: this.stepData
                            ? this.stepData.viewIds
                                ? this.stepData.viewIds
                                : this.stepData.selectViews
                                ? this.stepData.selectViews.id
                                : null
                            : null,
                        apiInfo:
                            this.listHttpService.listAclDependentViewsApiInfo(
                                value['aclId']
                            ),
                        showKey: 'name',
                        fieldType: FilterType.DROPDOWN_SINGLE,
                        required: true,
                        validations: [
                            {
                                validator: CustomValidators.required,
                                errorMessage: 'Views are required',
                            },
                        ],
                    },
                ],
            };
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    const updateControlInput = {
                        action: UpdateAction.REFRESH,
                        controls: ['selectViews'],
                    };
                    if (this.viewFormGroup) {
                        this.updateControlView.next(updateControlInput);
                        this.viewFormGroup.get('selectViews').reset();
                    }
                }, 0);
            });
        }
    }
    generateRootUserForm(checkBoxValue?: boolean) {
        if (checkBoxValue) {
            if (this.emailValidations.length > 3) {
                this.emailValidations.pop();
            }
            this.emailValidations.push({
                validator: CustomValidators.checkDomainInEmail(
                    this.customerInfoFirstFormRef
                        ? this.customerInfoFirstFormRef.value['domainId']
                        : null
                ),
                errorMessage: `Email Address should have the Domain ID ${
                    this.customerInfoFirstFormRef
                        ? this.customerInfoFirstFormRef.value['domainId']
                        : ''
                } `,
            });
        } else if (checkBoxValue === false) {
            if (this.emailValidations.length > 3) {
                this.emailValidations.pop();
            }
        }

        this.customerInfoSecondFormGenInput = null;
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.customerInfoSecondFormGenInput = {
                    formName: '',
                    submitButton: null,
                    state: FormState.CREATE,
                    fields: [
                        {
                            label: 'First Name',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter First Name',
                            name: 'rootUserFirstName',
                            fieldType: FilterType.TEXT,
                            validations: [
                                {
                                    validator: CustomValidators.required,
                                    errorMessage: 'First name is required',
                                },
                            ],
                            value: this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'rootUserFirstName'
                                  ]
                                : this.stepData
                                ? this.stepData['rootUserFirstName']
                                : null,
                        },
                        {
                            label: 'Last Name',
                            required: false,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter Last Name',
                            name: 'rootUserLastName',
                            fieldType: FilterType.TEXT,
                            value: this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'rootUserLastName'
                                  ]
                                : this.stepData
                                ? this.stepData['rootUserLastName']
                                : null,
                        },
                        {
                            label: 'Email ID',
                            required: true,
                            appearance: 'legacy',
                            showLabel: true,
                            placeholder: 'Enter Email Id',
                            name: 'rootUserEmail',
                            fieldType: FilterType.TEXT,
                            value: this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'rootUserEmail'
                                  ]
                                : this.stepData
                                ? this.stepData['rootUserEmail']
                                : null,
                            validations: this.emailValidations,
                        },
                        {
                            label: 'Contact Number',
                            appearance: 'legacy',
                            showLabel: true,
                            name: 'phoneNumber',
                            fieldType: FilterType.NUMBER,
                            required: false,
                            placeholder: 'Enter Contact Number',
                            value: this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'phoneNumber'
                                  ]
                                : this.stepData
                                ? this.stepData['phoneNumber']
                                : null,
                        },
                        {
                            label: 'Password',
                            placeholder: 'Enter Password',
                            fieldType: FilterType.PASSWORD,
                            appearance: 'legacy',
                            showLabel: true,
                            required: true,
                            validations: Helper.getPasswordValidators(),
                            suffixIcon: {
                                iconData: {
                                    type: IconType.MATICON,
                                    class: 'edit',
                                },
                                hoverText: 'Generate Password',
                                function: this.generatePassword.bind(this),
                            },
                            value: this.stepData
                                ? this.stepData['password']
                                : this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'password'
                                  ]
                                : '',
                            name: 'password',
                            extraClass: 'password-field',
                        },
                        {
                            label: 'Confirm Password',
                            placeholder: 'Confirm Password',
                            name: 'confirmPassword',
                            fieldType: FilterType.PASSWORD,
                            showLabel: true,
                            appearance: 'legacy',
                            required: true,
                            validations: Helper.getPasswordValidators(
                                'equal',
                                'password'
                            ),
                            value: this.stepData
                                ? this.stepData['confirmPassword']
                                : this.customerInfoSecondFormRef
                                ? this.customerInfoSecondFormRef.value[
                                      'confirmPassword'
                                  ]
                                : '',
                            extraClass: 'password-field',
                        },
                    ],
                };
            }, 0);
        });
    }
    updateTags($event) {
        this.tagsAssigned = $event['tagsData'];
        if ($event['tagToBeDeleted']) {
            this.tagsToBeDeleted.push($event['tagToBeDeleted']);
        }

        if ($event['deleteTags']) {
            this.prepareFinalTags($event);
        }
    }
    prepareFinalTags($event) {
        let tags = [...this.tagsAssigned];
        tags = Helper.removeDuplicateObjetsFromArray(tags);
        if (this.tagsToBeDeleted.length) {
            this.tagsToBeDeleted.forEach((tagsToBeDeleted) => {
                const tagsIndex = tags.findIndex(
                    (tag) => tag.name === this.tagsToBeDeleted
                );
                if (tagsIndex > -1) {
                    tags = Helper.removeIndexfromArray(tagsIndex, tags);
                }
            });
        }
        this.selectedTagsData = tags;

        this.finalTagsData['tags'] = tags;
        if ($event['callback']) {
            $event['callback']('SUCCESS');
        }
    }

    generatePassword() {
        let password = Helper.generateUniqueKey(16);
        password += 'a@B1';
        this.customerInfoSecondFormRef.get('password').setValue(password);
        this.customerInfoSecondFormRef
            .get('confirmPassword')
            .setValue(password);
    }

    private nextStep(buttonRef) {
        Helper.markAllFieldAsTouched(this.customerInfoFirstFormRef);
        Helper.markAllFieldAsTouched(this.customerInfoThirdFormRef);
        if (this.viewFormGroup) {
            Helper.markAllFieldAsTouched(this.viewFormGroup);
        }
        if (!this.edit) {
            Helper.markAllFieldAsTouched(this.customerInfoSecondFormRef);
            this.customerInfoSecondFormRef
                .get('confirmPassword')
                .updateValueAndValidity();
        }

        if (this.customerInfoFirstFormRef.invalid) {
            return;
        }
        if (!this.edit && this.customerInfoSecondFormRef.invalid) {
            this.customerInfoSecondFormRef.updateValueAndValidity();
            return;
        }
        if (this.customerInfoThirdFormRef.invalid) {
            return;
        }
        if (this.viewFormGroup && this.viewFormGroup.invalid) {
            return;
        }

        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(1, this.createObject());
        if (this.edit) {
            this.updateCustomer(buttonRef);
        } else {
            this.multiStepFormService.nextStep(this.modalInputData.modalId);
        }
    }
    createObject() {
        const tags = this.finalTagsData.length
            ? this.finalTagsData
            : this.selectedTagsData;
        if (this.edit) {
            return {
                ...this.customerInfoFirstFormRef.getRawValue(),
                ...this.customerInfoThirdFormRef.getRawValue(),
                tags,
            };
        }
        return {
            ...this.customerInfoFirstFormRef.getRawValue(),
            ...this.customerInfoSecondFormRef.getRawValue(),
            ...this.customerInfoThirdFormRef.getRawValue(),
            ...this.viewFormGroup.getRawValue(),
            tags,
        };
    }

    private updateCustomer(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const input = this.prepareUpdateInput();
        const apiConf: IHitApi = {
            url: `${ApiUrls.TENANT_ENDPOINT}${this.stepData['tenantId']}`,
            intactUrl: `${ApiUrls.TENANT_ENDPOINT}{id}`,
            input: input,
            function: (response) => {
                buttonRef.loader = false;
                this.notificationService.showSnackBar(
                    `Customer updated successfully`
                );
                this.filtersService.refreshWidget.next(
                    new Set([this.widgetRef.widgetData.widgetUniqueIdentifier])
                );
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            requestType: RequestType.PATCH,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationService,
                    error,
                    `Error updating ${this.stepData.domainId}`
                );
            },
        };
        new HitApi(apiConf, this.httpService, this.httpService.ngZone).hitApi();
    }

    private prepareUpdateInput() {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const input = {
            companyName: step1Data.companyName,
            dataRetentionMonths: step1Data.dataRetentionMonths,
            dataSourceId: step1Data.dataSourceId,
            aclId: step1Data.aclId,
            tags: step1Data.tags,
            priceType: step1Data.priceType,
            allowDomainBasedUsers: step1Data.checkBox ? true : false,
        };
        return input;
    }
}
