import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { AssessmentCacheService } from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ViewHttpService } from 'src/app/shared/services/http/view-http/view-http.service';
import { Logger } from '../../classes/Logger';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { FilterStoreKey } from '../../enums/FilterStoreKey';
import { RequestType } from '../../enums/RequestType';
import { IHitApi } from '../../interfaces/hit-api/IHitApi';
import { ConfigCacheService } from '../cache/config-cache/config-cache.service';
import { FilterCacheService } from '../cache/filters-cache/filter-cache.service';
import {
    MiscellaneousCacheService,
    SSO_REDIRECT_URL
} from '../cache/miscellaneous-cache/miscellaneous-cache.service';
import { SideMenuCacheService } from '../cache/side-menu-cache/side-menu-cache.service';
import { WidgetCacheService } from '../cache/widget-cache/widget-cache.service';
import { GlobalDataService } from '../global-data/global-data.service';
import { HttpService } from '../http/http-main/http.service';
import { WhiteLabelingService } from '../white-labeling/white-labeling.service';
import { ApiUrls } from './../../../core/classes/ApiUrls';
import { HitApi } from './../../classes/HitApi';
import { IGeneralConfigResponse } from './../../interfaces/api/portlets/IGeneralConfigResponse';
import { ILoginResponse } from './../../interfaces/api/portlets/ILoginResponse';
import { CredentialsCacheService } from './../cache/credentials-cache/credentials-cache.service';
import { NotificationsService } from './../notifications/notifications.service';
import { UserDataCacheService } from './../user-data-cache/user-data-cache.service';
import { IotNewService } from '../iot/iot-new.service';
import { ModalService } from '../modal/modal-service/modal.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    readonly SESSION_HEADER_DATA_KEY = 'headerData';

    sessionActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    checkInterval: ReturnType<typeof setInterval>;
    bannerId;

    constructor(
        private configCache: ConfigCacheService,
        private filterCacheService: FilterCacheService,
        private sideMenuCache: SideMenuCacheService,
        private widgetCache: WidgetCacheService,
        private httpService: HttpService,
        private globalDataService: GlobalDataService,
        private filtersService: FiltersService,
        private router: Router,
        private credentialsCacheService: CredentialsCacheService,
        private userDataCache: UserDataCacheService,
        private whitelabelService: WhiteLabelingService,
        private assessmentCacheService: AssessmentCacheService,
        private miscellaneousCacheService: MiscellaneousCacheService,
        private notificationsService: NotificationsService,
        private iotNewService: IotNewService,
        private modalService: ModalService
    ) {}

    login(
        response: ILoginResponse,
        viewHttpService: ViewHttpService,
        reload?: boolean,
        errorCallback?,
        callBackFn?
    ) {
        const decodedToken = jwt_decode(response.tokens.bearerToken);
        const tokenValidationTime = new Date(
            new Date(decodedToken['exp'] * 1000).toISOString()
        );
        this.checkInterval = setInterval(() => {
            const currentTime = new Date(new Date().toISOString());
            if (tokenValidationTime <= currentTime) {
                this.logout();
                this.bannerId = this.notificationsService.showBanner(
                    'Your session has expired. Please log in again'
                );
            }
        }, 60000 * 30);
        if (
            (!response ||
                !response.tokens ||
                !response.tokens.bearerToken ||
                !response.tokens.oldTokens ||
                !Object.keys(response.tokens.oldTokens).length) &&
            errorCallback
        ) {
            errorCallback();
            return;
        }
        this.globalDataService.fullPageLoader.next(true);
        this.notificationsService.closeBanner(this.bannerId);
        this.userDataCache.emailId = response.email ? response.email : '';
        this.userDataCache.firstName = response.firstName
            ? response.firstName
            : '';
        this.userDataCache.lastName = response.lastName
            ? response.lastName
            : '';
        if (response && response['assessmentId']) {
            this.userDataCache.isAssesseeView = true;
            if (!this.userDataCache.isAssessorView) {
                this.assessmentCacheService.assessmentId =
                    response['assessmentId'];
            }
        }
        this.userDataCache.mfaStatus = response.enabledMfa
            ? response.enabledMfa
            : false;
        this.userDataCache.rootUser = response.root ? response.root : false;
        this.userDataCache.domainId = response.domain ? response.domain : '';
        this.userDataCache.allowDomainBasedUsers = response.allowDomainBasedUser
            ? response.allowDomainBasedUser
            : false;
        this.userDataCache.privacyPolicyFile = response?.privacyPolicyFile
            ? response?.privacyPolicyFile
            : '';
        this.userDataCache.termsOfServicesFile = response?.licenseAgreementFile
            ? response?.licenseAgreementFile
            : '';
        this.userDataCache.showLicenseAgreement = response?.showLicenseAgreement
            ? response?.showLicenseAgreement
            : false;

        // if (extraData && extraData.domainId) {
        //     this.userDataCache.domainId = extraData.domainId;
        // }
        // this.userDataCache.companyName = response.companyName
        //     ? response.companyName
        //     : '';
        // if (response.companyName) {
        //     this.whitelabelService.whiteLableData.companyName =
        //         response.companyName;
        // }
        this.setAuthorizationTokens(response);
        this.configCache.freshLogin = true;
        this.globalDataService.freshLogin = true;
        this.hitSideMenuApi(
            response,
            viewHttpService,
            reload,
            errorCallback,
            callBackFn
        );
    }

    logout(isNormalLogout: Boolean = false, redirectToHome: Boolean = true) {
        if (
            this.modalService.currentlyOpenedModals &&
            this.modalService.currentlyOpenedModals?.length
        ) {
            for (
                let i = 0;
                i < this.modalService.currentlyOpenedModals.length;
                i++
            ) {
                this.modalService.closeModal(
                    null,
                    this.modalService.currentlyOpenedModals[i]
                );
            }
        }
        this.iotNewService.clearIotConnections('', true);
        clearInterval(this.checkInterval);
        Logger.codeLog(redirectToHome);
        if (GlobalConfiguration.PREVIEW_MODE) {
            this.globalDataService.fullPageLoader.next(false);
            return;
        }
        //Resetting the filter
        this.filtersService.filtersData.set(
            FilterStoreKey.WEBSITE_FILTERS,
            new Map(this.filtersService.filtersDefaultValue)
        );
        if (!this.globalDataService.beginsAssessmentLogin) {
            this.globalDataService.assesseeCreds.next(null);
        }
        localStorage.clear();
        sessionStorage.clear();
        this.widgetCache.deleteCollection();
        if (redirectToHome) {
            this.router.navigate(['']);
        }
        this.sessionActive.next(false);
        this.globalDataService.fullPageLoader.next(false);
        this.globalDataService.routingData.next(null);
        if (!isNormalLogout) {
            this.checkIfUrlPresentBeforeLogin();
        } else {
            this.globalDataService.loadWidgetsFromApiArgs.next(null);
        }
    }

    hitSideMenuApi(
        loginResponse: ILoginResponse,
        viewHttpService: ViewHttpService,
        reload?,
        errorCallback?,
        callback?
    ) {
        let sideBarApi;

        const defaultViewId = loginResponse.defaultViewId;
        if (defaultViewId && defaultViewId.includes('http')) {
            const splitted = defaultViewId.split('/');
            this.globalDataService.selectedView = splitted[splitted.length - 1];
        } else {
            this.globalDataService.selectedView = defaultViewId
                ? defaultViewId
                : loginResponse['assessmentId'];
        }
        const viewId = loginResponse['assessmentId']
            ? loginResponse['assessmentId']
            : this.globalDataService.selectedView;
        if (
            this.globalDataService.beginsAssessmentLogin ||
            loginResponse['assessmentId']
        ) {
            sideBarApi = ApiUrls.ASSESSMENT_SIDE_MENU_DATA;
        }

        viewHttpService.getSidebarDataForView(
            viewId,
            (sideMenuApiResponse) => {
                this.globalDataService.beginsAssessmentLogin = false;
                this.configCache.viewId = this.globalDataService.selectedView;
                this.setSideMenuFirstLevelDataData(
                    this.globalDataService.selectedView,
                    sideMenuApiResponse,
                    reload,
                    false,
                    callback
                );
            },
            (error) => {
                if (errorCallback) {
                    errorCallback();
                }
                this.globalDataService.fullPageLoader.next(false);
                this.logout();
            },
            sideBarApi
        );
    }

    setSideMenuFirstLevelDataData(
        viewId,
        sideMenuData,
        reload?: boolean,
        freshLogin?: boolean,
        callback?
    ) {
        if (reload) {
            // this.sideMenuCache.deleteCollection();
            this.widgetCache.deleteCollection();
        }
        this.globalDataService.selectedView = viewId;
        this.configCache.viewId = viewId;
        let data = this.sideMenuCache.firstLevelData;
        if (!data) {
            data = {};
        }

        data[viewId] = sideMenuData;
        this.sideMenuCache.firstLevelData = data;
        this.globalDataService.sideMenuStepOne = sideMenuData;
        this.getFiltersOrdering(() => {
            if (callback) {
                callback();
            }
            if (freshLogin) {
                this.configCache.freshLogin = true;
            }
            if (reload) {
                window.location.reload();
            } else {
                this.verifySession();
            }
        });
    }

    getFiltersOrdering(callBack?) {
        if (this.userDataCache.isAssesseeView) {
            if (callBack) {
                callBack();
            }
            return;
        }
        new HitApi(
            {
                url: ApiUrls.SORTED_FILTERS_LIST_API_PATH,
                requestType: RequestType.GET,
                input: {},
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response: { filters }) => {
                    this.filterCacheService.sortedFilters = Object.keys(
                        response.filters
                    );
                },
                errorFunction: (error) => {
                    this.filterCacheService.sortedFilters = null;
                },
                endFunction: () => {
                    this.getGeneralConfigFilters(callBack);
                }
            },
            this.httpService,
            this.httpService.ngZone
        ).hitApi();
    }

    getGeneralConfigFilters(callBack?) {
        new HitApi(
            {
                url: ApiUrls.GENERAL_CONFIGURATION_FILTERS_LIST_API_PATH,
                requestType: RequestType.GET,
                input: {},
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                },
                function: (response: {
                    filterConfigured: boolean;
                    filters;
                }) => {
                    this.resetFilters();
                    if (response.filterConfigured) {
                        this.filterCacheService.setDefaultFilters(
                            this.userDataCache.emailId,
                            this.globalDataService.selectedView,
                            response.filters
                        );
                        this.filtersService.filtersData.set(
                            FilterStoreKey.CONFIGURATION,
                            new Map(Object.entries(response.filters))
                        );
                        this.filtersService.filtersData.set(
                            FilterStoreKey.WEBSITE_FILTERS,
                            new Map(Object.entries(response.filters))
                        );
                        this.filtersService.filtersDataTemp.set(
                            FilterStoreKey.CONFIGURATION,
                            new Map(Object.entries(response.filters))
                        );
                        this.filtersService.filtersDataTemp.set(
                            FilterStoreKey.WEBSITE_FILTERS,
                            new Map(Object.entries(response.filters))
                        );
                    }
                },
                errorFunction: (error) => {
                    // this.filterCacheService.sortedFilters = null;
                },
                endFunction: () => {
                    callBack();
                    this.getEmailTemplate();
                }
            },
            this.httpService,
            this.httpService.ngZone
        ).hitApi();
    }
    getEmailTemplate() {
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSMENT_EMAIL_TEMPLATE,
            requestType: RequestType.GET,
            input: {},
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.configCache.assessmentTemplate = data.id;
            },
            errorFunction: (error) => {}
        };
        apiArgs.url = apiArgs.url.replace('{email-template-type}', 'ASSESSMENT_ASSESSE');
        new HitApi(apiArgs, this.httpService, this.httpService.ngZone).hitApi();
    }
    resetFilters() {
        this.filtersService.filtersData.delete(FilterStoreKey.CONFIGURATION);
        this.filtersService.filtersData.delete(FilterStoreKey.WEBSITE_FILTERS);

        this.filtersService.filtersDataTemp.delete(
            FilterStoreKey.CONFIGURATION
        );
        this.filtersService.filtersDataTemp.delete(
            FilterStoreKey.WEBSITE_FILTERS
        );
    }

    setAuthorizationTokens(loginResponse: ILoginResponse) {
        this.httpService.bearerToken = loginResponse['tokens']['bearerToken'];
        this.credentialsCacheService.saveViewToken(
            loginResponse.email,
            loginResponse.defaultViewId,
            loginResponse.tokens.bearerToken
        );
        this.httpService.authorizationToken =
            loginResponse['tokens']['oldTokens']['authorizationToken'];

        // Setting IOT Config
        this.httpService.iotConfig =
            loginResponse.tokens.oldTokens.iotCredentials;
    }

    beginIotAndLogin() {
        this.httpService.iotService.makeConnection(this.httpService.iotConfig);
        this.httpService.iotService.status
            .pipe(skip(1), take(1))
            .subscribe((status) => {
                if (!status) {
                    this.logout();
                } else if (!this.globalDataService.beginsAssessmentLogin) {
                    this.sessionActive.next(status);
                }
            });
    }

    checkIfUrlPresentBeforeLogin() {
        // Checking if domain id and email id is present in base url for SSO handelling
        if (
            window.location.search &&
            window.location.search.includes('domainId') &&
            window.location.search.includes('emailId')
        ) {
            const id = window.location.search
                .split('domainId=')[1]
                .split('&')[0];
            const email = window.location.search.split('emailId=')[1];
            this.globalDataService.ssoRedirectionCreds = {
                domainId: id,
                email: email
            };
            this.miscellaneousCacheService.store(
                SSO_REDIRECT_URL,
                window.location.pathname
            );
        }
        const tempURL = window.location.pathname.split('/');
        this.globalDataService.enteredUrlBeforeLogin = tempURL.filter((e) => {
            return e !== '';
        });
    }
    verifySession(redirectToHome?) {
        if (this.globalDataService.returnToConsole) {
            this.globalDataService.returnToConsole = false;
        } else {
            this.globalDataService.fullPageLoader.next(true);
        }
        if (
            this.httpService.bearerToken &&
            this.httpService.iotConfig &&
            this.sideMenuCache.firstLevelData &&
            this.configCache.viewId &&
            this.sideMenuCache.getFirstLevelData(this.configCache.viewId)
        ) {
            const defaultFilters = this.filterCacheService.getDefaultFilters(
                this.userDataCache.emailId,
                this.configCache.viewId
            );

            if (defaultFilters) {
                // Setting default filters data
                if (!this.filtersService.filtersData) {
                    this.filtersService.filtersData = new Map();
                }
                this.filtersService.filtersData.set(
                    FilterStoreKey.WEBSITE_FILTERS,
                    new Map(Object.entries(defaultFilters))
                );

                this.filtersService.filtersData.set(
                    FilterStoreKey.CONFIGURATION,
                    new Map(Object.entries(defaultFilters))
                );

                this.filtersService.filtersDataTemp.set(
                    FilterStoreKey.WEBSITE_FILTERS,
                    new Map(Object.entries(defaultFilters))
                );
                this.filtersService.filtersDataTemp.set(
                    FilterStoreKey.CONFIGURATION,
                    new Map(Object.entries(defaultFilters))
                );
                if (this.configCache.freshLogin) {
                    const filterData = {
                        [FilterStoreKey.WEBSITE_FILTERS]: defaultFilters,
                        [FilterStoreKey.CONFIGURATION]: defaultFilters
                    };
                    this.filterCacheService.setFiltersInfo(
                        this.userDataCache.emailId,
                        this.configCache.viewId,
                        filterData
                    );
                }
            }

            this.globalDataService.selectedView = this.configCache.viewId;
            this.globalDataService.sideMenuStepOne =
                this.sideMenuCache.getFirstLevelData(this.configCache.viewId);
            if (this.configCache.freshLogin) {
                this.globalDataService.freshLogin = true;
                this.configCache.freshLogin = false;
                this.filtersService.convertTempToMain(
                    FilterStoreKey.WEBSITE_FILTERS,
                    true
                );
                this.filtersService.convertTempToMain(
                    FilterStoreKey.CONFIGURATION,
                    true
                );
            } else {
                this.filtersService.assignWebPageFilters();
            }
            this.beginIotAndLogin();
        } else {
            this.logout(false, redirectToHome);
        }
    }
}
