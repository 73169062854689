import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AfterIfDirective } from '../../directives/after-if/after-if.directive';
import { ComponentLoaderDirective } from '../../directives/component-loader/component-loader.directive';
import { DisableElementDirective } from '../../directives/disable-element/disable-element.directive';
import { DragDropFileSelectDirective } from '../../directives/drag-drop-file-select/drag-drop-file-select.directive';
import { IconDirective } from '../../directives/icon/icon.directive';
import { ResizeObserverDirective } from '../../directives/resizeObserver/resize-observer.directive';
import { VerticalScrollingTabDirective } from '../../directives/vertical-scrolling-tab/vertical-scrolling-tab.directive';
import { AggregateCardColorPipe } from '../../pipes/aggregate-card-color.pipe';
import { AttributeToIconDirectivePipe } from '../../pipes/attribute-to-icon-directive.pipe';
import { CamelCaseToNormalNamePipe } from '../../pipes/camel-case-to-normal-name.pipe';
import { CardButtonsDereferencerPipe } from '../../pipes/card-buttons-dereferencer/card-buttons-dereferencer.pipe';
import { CardDataRendererPipe } from '../../pipes/card-data-renderer/card-data-renderer.pipe';
import { CreateFilterInfoPipe } from '../../pipes/create-filter-info/create-filter-info.pipe';
import { DescriptionContentGeneratorPipe } from '../../pipes/description-content-generator.pipe';
import { EmptyObjectPipe } from '../../pipes/empty-object/empty-object.pipe';
import { ExtractValueFromTagPipe } from '../../pipes/extract-value-from-tag.pipe';
import { FieldDataFromControlNamePipe } from '../../pipes/field-data-from-control-name/field-data-from-control-name.pipe';
import { FormErrorValidationPipe } from '../../pipes/form-error-validation/form-error-validation.pipe';
import { GenerateReportIconDataPipe } from '../../pipes/generate-report-icon-data/generate-report-icon-data.pipe';
import { GetAssessmentStatusPipe } from '../../pipes/get-assessment-status/get-assessment-status.pipe';
import { GetFilterSelectedValuePipe } from '../../pipes/get-filter-selected-value.pipe';
import { IconDataGeneratorPipe } from '../../pipes/icon-data-generator/icon-data-generator.pipe';
import { IconSideNavBackgroundPipe } from '../../pipes/icon-sidenav-background.pipe';
import { ImageUrlFromFilePipe } from '../../pipes/image-url-from-file/image-url-from-file.pipe';
import { NLevelRoutePipe } from '../../pipes/n-level-route-pipe/n-level-route-pipe.pipe';
import { ObjectDataExtractorPipe } from '../../pipes/object-data-extractor/object-data-extractor.pipe';
import { RedirectionUrlGenerator } from '../../pipes/redirection-url-generator.pipe';
import { RegexExtractPipe } from '../../pipes/regex-extract.pipe';
import { SafeRenderPipe } from '../../pipes/safe-render.pipe';
import { SvgIconNameConverterPipe } from '../../pipes/svg-icon-name-converter.pipe';
import { WidgetsPageSeparationPipe } from '../../pipes/widgets-page-separation/widgets-page-separation.pipe';

const directivesAndPipes = [
    ResizeObserverDirective,
    ComponentLoaderDirective,
    DisableElementDirective,
    AfterIfDirective,
    IconDirective,
    SafeRenderPipe,
    CreateFilterInfoPipe,
    NLevelRoutePipe,
    DescriptionContentGeneratorPipe,
    GenerateReportIconDataPipe,
    CamelCaseToNormalNamePipe,
    FormErrorValidationPipe,
    ImageUrlFromFilePipe,
    EmptyObjectPipe,
    FieldDataFromControlNamePipe,
    ObjectDataExtractorPipe,
    CardDataRendererPipe,
    CardButtonsDereferencerPipe,
    AttributeToIconDirectivePipe,
    WidgetsPageSeparationPipe,
    IconSideNavBackgroundPipe,
    SvgIconNameConverterPipe,
    IconDataGeneratorPipe,
    AggregateCardColorPipe,
    DragDropFileSelectDirective,
    VerticalScrollingTabDirective,
    RedirectionUrlGenerator,
    GetFilterSelectedValuePipe,
    RegexExtractPipe,
    GetAssessmentStatusPipe,
    ExtractValueFromTagPipe
];

@NgModule({
    declarations: [...directivesAndPipes],
    imports: [CommonModule],
    exports: [...directivesAndPipes]
})
export class CommonPipesModule {}
