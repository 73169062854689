import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IntegrationStates } from 'src/app/shared/classes/IntegrationStates';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IIntegrationApiResponse } from 'src/app/shared/interfaces/api/portlets/IIntegrationApiResponse';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IIntegrationInput } from 'src/app/shared/interfaces/integration/IIntegrationInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { EmailTestingModalComponent } from '../../../modal-templates/email-testing-modal/email-testing-modal.component';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';

@Component({
    selector: 'app-gmail-integration',
    templateUrl: './gmail-integration.component.html',
    styleUrls: ['./gmail-integration.component.sass']
})
export class GmailIntegrationComponent implements OnInit, AfterViewInit {
    integrationInput: IIntegrationInput = null;
    widgetRef: Widget;
    isIntegrated = false;
    isConfigured: Boolean = false;

    constructor(
        widgetData: WidgetInjectedData,
        private redirectionHandleService: RedirectionHandleService,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.setUpBasics();
    }

    setUpBasics() {
        this.integrationInput = {
            integrationName: this.widgetRef.widgetData.widgetInfo.text,
            integrationCategory: 'Mail Integration',
            imageUrl: 'assets/integrations/gmail-plugin.png',
            buttons: []
        };
    }

    generateButtons(response: IIntegrationApiResponse) {
        if (this.isIntegrated) {
            // Adding Play Button
            this.integrationInput.buttons.push({
                buttonName: 'Test',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-play'
                },
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                hoverText: 'Test Gmail',
                function: () => {
                    const modalArgs: IModalData = {
                        modalName: 'Gmail Test Email',
                        sourceId: Symbol(),
                        modalType: ModalType.MIDDLE,
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-play'
                        },
                        extraStepContainerClass: 'remove-form-space',
                        modalSteps: [
                            {
                                stepName: 'Gmail Email Testing',
                                resetModal: true,
                                stepData: {
                                    componentToLoad: EmailTestingModalComponent,
                                    payload: {
                                        data: (
                                            buttonRef: IButtonGeneratorInput,
                                            formGroup: FormGroup,
                                            modalId: Symbol
                                        ) => {
                                            Helper.markAllFieldAsTouched(
                                                formGroup
                                            );
                                            if (formGroup.invalid) {
                                                formGroup.updateValueAndValidity();
                                                return;
                                            }
                                            if (formGroup.valid) {
                                                buttonRef.loader = true;
                                                const apiArgs =
                                                    Helper.generateHitApiConfig(
                                                        this.widgetRef
                                                            .widgetData
                                                            .widgetInfo.action
                                                    );
                                                apiArgs.intactUrl = apiArgs.url;
                                                apiArgs.url =
                                                    apiArgs.url.replace(
                                                        '{integration-id}',
                                                        response.id
                                                    );
                                                apiArgs.input = formGroup.value;
                                                apiArgs.function = (
                                                    response
                                                ) => {
                                                    buttonRef.loader = false;
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        'Email sent successfully'
                                                    );
                                                    this.widgetRef.modalService.closeModal(
                                                        null,
                                                        modalId
                                                    );
                                                };
                                                apiArgs.errorFunction = (
                                                    error
                                                ) => {
                                                    buttonRef.loader = false;
                                                    this.widgetRef.notificationsService.showSnackBar(
                                                        'Error sending email',
                                                        true
                                                    );
                                                };
                                                new HitApi(
                                                    apiArgs,
                                                    this.widgetRef.httpService,
                                                    this.widgetRef.ngZone
                                                ).hitApi();
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        modalWidthVw: 60,
                        modalHeightVh: 70
                    };
                    this.widgetRef.modalService.openModal(modalArgs);
                }
            });

            // Adding Delete Button
            this.integrationInput.buttons.push({
                buttonName: 'Delete',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-trash'
                },
                hoverText: 'Remove Gmail',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.WARN,
                function: (modalId: Symbol) => {
                    // Delete integration
                    const args = Helper.generateHitApiConfig(
                        this.widgetRef.widgetData.widgetInfo.delete
                    );
                    args.input = {};
                    args.function = (response) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            `Gmail integration removed successfully`
                        );
                        this.widgetRef.refreshWidget();
                        this.modalService.closeModal(null, modalId);
                    };
                    args.errorFunction = (error) => {
                        this.widgetRef.notificationsService.showSnackBar(
                            error.error.message,
                            true
                        );
                        this.widgetRef.refreshWidget();
                    };
                    new HitApi(
                        args,
                        this.widgetRef.httpService,
                        this.widgetRef.ngZone
                    ).hitApi();
                },
                executeFunctionAfterConfirmation: {
                    modalName: 'Remove Gmail Integration',
                    modalIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'far fa-trash-alt'
                    },
                    contextIcon: {
                        extraClass: 'color-accent',
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-exclamation-triangle'
                    },
                    confirmationMessage: `Are you sure you want to remove gmail ?`,
                    buttonText: 'Remove',
                    buttonColorType: ButtonColorType.WARN,
                    loaderOnExec: true
                }
            });
        } else {
            // Adding Integrate Button
            this.integrationInput.buttons.push({
                buttonName: 'Integrate',
                buttonIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-plus'
                },
                hoverText: 'Integrate Gmail',
                buttonType: ButtonType.CIRCLE,
                buttonColorType: ButtonColorType.SUCCESS,
                function: (buttonRef) => {
                    this.onIntegrate(buttonRef);
                }
            });
        }
    }

    bindData(response: IIntegrationApiResponse): void {
        this.isConfigured = false;
        this.isIntegrated = response.integration;
        if (this.isIntegrated) {
            this.isConfigured = true;
        }
        this.integrationInput.buttons = [];
        this.generateButtons(response);
        this.widgetRef.endLoader();
    }

    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
    onIntegrate(buttonRef: any) {
        this.redirectionHandleService.widgetIdUsedForRedirection =
            this.widgetRef.widgetData.widgetId;
        const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GlobalConfiguration.GMAIL_CLIENT_ID}&redirect_uri=${this.redirectionHandleService.redirectionUrl}&response_type=code&scope=https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify&access_type=offline&state=${IntegrationStates.GMAIL_STATE}`;
        this.redirectionHandleService.redirectTo(
            this.widgetRef.widgetData.widgetId,
            url
        );
    }
}
