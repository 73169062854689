import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { IFormGeneratorInput } from './../../../../interfaces/form-generator/IFormGeneratorInput';
import { WhiteLabelingService } from './../../../../services/white-labeling/white-labeling.service';
import { Widget } from 'src/app/shared/classes/Widget';

@Component({
    selector: 'app-create-customer-step-three',
    templateUrl: './create-customer-step-three.component.html',
    styleUrls: ['./create-customer-step-three.component.sass'],
})
export class CreateCustomerStepThreeComponent implements OnInit {
    retentionPeriod = 9;
    stepData: any = null;

    checkBoxFormGenInput: IFormGeneratorInput = null;
    checkBoxFormGroup: FormGroup;
    emailContentFormGenInputs: IFormGeneratorInput = null;
    emailContentFormGroup: FormGroup;
    emailSubject: string;
    emailContent: string;
    widgetRef: Widget;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.multiStepFormService.previousStep(
                    this.modalInputData.modalId
                );
            },
        },
        {
            buttonName: 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.createCustomer(buttonRef);
            },
        },
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between',
        },
    };
    resetEmailContent;

    constructor(
        public modalInputData: ModalInjectedData,
        private httpService: HttpService,
        private ngZone: NgZone,
        public multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private whiteLabelService: WhiteLabelingService,
        private modalService: ModalService,
        private filtersService: FiltersService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.editContents();
        if (
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(2)
        ) {
            this.stepData = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(2);
        }
        this.setUpBasis();
        this.modalService.resetModal.subscribe((data) => {
            this.editContents();

            this.emailContentFormGroup
                .get('emailContent')
                .setValue(this.resetEmailContent);

            this.editContents();
        });
    }

    editContents() {
        let step2Data;
        if (
            this.multiStepFormService.stepData &&
            this.multiStepFormService.stepData.has(
                this.modalInputData.modalId
            ) &&
            this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .has(1)
        ) {
            step2Data = this.multiStepFormService.stepData
                .get(this.modalInputData.modalId)
                .get(1);
        }

        if (
            this.whiteLabelService &&
            this.whiteLabelService.whiteLableData &&
            this.whiteLabelService.whiteLableData.customerContent
        ) {
            this.emailSubject =
                this.whiteLabelService.whiteLableData.customerSubject.replace(
                    /{{companyName}}/g,
                    this.whiteLabelService.whiteLableData.companyName
                );
            this.emailContent =
                this.whiteLabelService.whiteLableData.customerContent.replace(
                    /{{companyName}}/g,
                    this.whiteLabelService.whiteLableData.companyName
                );

            const fullName = (
                step2Data.rootUserFirstName +
                ' ' +
                (step2Data.rootUserLastName ? step2Data.rootUserLastName : '')
            ).trim();

            const titleCase = new TitleCasePipe().transform(fullName);
            this.emailContent = this.emailContent.replace(
                /{{user}}/g,
                titleCase
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{email}}',
                step2Data.rootUserEmail
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{password}}',
                step2Data.password
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{domain}}',
                step2Data.domainId
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{domainName}}',
                location.host
            );

            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{domainId}}',
                step2Data.domainId
            );

            this.resetEmailContent = Helper.cloneDeep(this.emailContent);
        }
    }
    setUpBasis() {
        this.checkBoxFormGenInput = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Send Email',
                    placeholder: '',
                    name: 'send',
                    fieldType: FilterType.CHECKBOX,
                    value: true,
                    showLabel: true,
                    extraClass: 'accent-checkbox',
                },
            ],
        };
        this.cdr.detectChanges();
        this.emailContentFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Subject',
                    placeholder: '',
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    value: this.emailSubject ? this.emailSubject : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Subject is required',
                        },
                    ],
                },
                {
                    label: 'Email Content',
                    placeholder: '',
                    name: 'emailContent',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    value: this.emailContent ? this.emailContent : '',
                },
            ],
        };
    }
    previousStep() {
        this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .set(2, this.prepareInputs());
        this.multiStepFormService.previousStep(this.modalInputData.modalId);
    }
    createCustomer(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }

        if (
            this.checkBoxFormGenInput &&
            this.checkBoxFormGroup.value &&
            this.checkBoxFormGroup.value.send
        ) {
            Helper.markAllFieldAsTouched(this.emailContentFormGroup);
            if (this.emailContentFormGroup.invalid) {
                this.emailContentFormGroup.updateValueAndValidity();
                return;
            }
        }

        buttonRef.loader = true;
        const apiConf: IHitApi = {
            url: ApiUrls.CUSTOMERS_END_POINT,
            input: this.prepareInputs(),
            function: (response) => {
                this.notificationService.showSnackBar(
                    'Customer created successfully'
                );
                this.filtersService.refreshWidget.next(
                    new Set([this.modalService.modalData.sourceId])
                );
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.notificationService,
                    error,
                    'Error while creating customer'
                );
                buttonRef.loader = false;
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
        };

        new HitApi(apiConf, this.httpService, this.ngZone).hitApi();
    }
    prepareInputs() {
        const data = this.emailContentFormGroup.value;
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        const inputs = {
            companyName: step1Data.companyName,
            dataSourceId: step1Data.dataSourceId,
            domainId: step1Data.domainId,
            defaultViewId: step1Data.defaultViewId
                ? step1Data.defaultViewId
                : step1Data['selectViews']['id'],
            dataRetentionMonths: step1Data.dataRetentionMonths,
            aclId: step1Data.aclId,
            tags: step1Data.tags,
            priceType: step1Data.priceType,
            allowDomainBasedUsers: step1Data.checkBox ? true : false,
            user: {
                email: step1Data.rootUserEmail,
                password: step1Data.password,
                confirmPassword: step1Data.confirmPassword,
                firstName: step1Data.rootUserFirstName,
                lastName: step1Data.rootUserLastName,
                phoneNumber: step1Data.phoneNumber,

                tags: step1Data.finalTagsData,
                sendingMechanism: {
                    sendViaEmail: this.checkBoxFormGroup.value.send,
                    emailRequest: {
                        emailTo: [step1Data.rootUserEmail],
                        subject: data.subject,
                        emailBody: data.emailContent,
                    },
                },
            },
        };

        return inputs;
    }
}
