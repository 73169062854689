import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { ListHttpService } from './../../../../../services/http/list-http/list-http.service';

@Component({
    selector: 'app-create-user-step-two',
    templateUrl: './create-user-step-two.component.html',
    styleUrls: ['./create-user-step-two.component.sass'],
})
export class CreateUserStepTwoComponent implements OnInit {
    checkBoxFormGenInput: IFormGeneratorInput = null;
    checkBoxFormGroup: FormGroup;
    emailContentFormGenInputs: IFormGeneratorInput = null;
    emailContentFormGroup: FormGroup;
    emailSubject: string;
    emailContent: string;
    stepData: any = null;

    buttonGenInputs: IButtonGeneratorInput[] = [
        {
            buttonName: 'Back',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.multiStepFormService.previousStep(
                    this.modalInputData.modalId
                );
            },
        },
        {
            buttonName: 'Create',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            showLoader: true,
            function: (buttonRef) => {
                this.createUser(buttonRef);
            },
        },
    ];

    buttonOptions: IMultiButtonOption = {
        layout: {
            justifyContent: 'space-between',
        },
    };

    constructor(
        public modalInputData: ModalInjectedData,
        private ngZone: NgZone,
        public multiStepFormService: MultiStepFormService,
        private notificationService: NotificationsService,
        private whiteLabelService: WhiteLabelingService,
        private userDataCacheService: UserDataCacheService,
        private modalService: ModalService,
        private filtersService: FiltersService,
        private cdr: ChangeDetectorRef,
        private listHttpService: ListHttpService
    ) {}

    ngOnInit(): void {
        this.editContents();
        this.setUpBasis();
    }
    editContents() {
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);
        if (
            this.whiteLabelService &&
            this.whiteLabelService.whiteLableData &&
            this.whiteLabelService.whiteLableData.customerContent
        ) {
            this.emailSubject =
                this.whiteLabelService.whiteLableData.userSubject.replace(
                    /{{companyName}}/g,
                    this.whiteLabelService.whiteLableData.companyName
                );
            this.emailContent =
                this.whiteLabelService.whiteLableData.userContent.replace(
                    /{{companyName}}/g,
                    this.whiteLabelService.whiteLableData.companyName
                );

            const fullName = (
                step1Data.firstName +
                ' ' +
                (step1Data.lastName ? step1Data.lastName : '')
            ).trim();

            const titleCase = new TitleCasePipe().transform(fullName);

            this.emailContent = this.emailContent.replace(
                /{{user}}/g,
                titleCase
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{email}}',
                step1Data.email
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{password}}',
                step1Data.password
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{domainName}}',
                location.href
            );
            this.emailContent = Helper.replaceAll(
                this.emailContent,
                '{{domainId}}',
                this.userDataCacheService.domainId
            );
        }
    }
    setUpBasis() {
        this.checkBoxFormGenInput = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Send',
                    placeholder: '',
                    name: 'send',
                    fieldType: FilterType.CHECKBOX,
                    value: true,
                },
            ],
        };
        this.cdr.detectChanges();
        this.emailContentFormGenInputs = {
            formName: '',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    label: 'Subject',
                    placeholder: '',
                    name: 'subject',
                    fieldType: FilterType.TEXT,
                    value: this.emailSubject ? this.emailSubject : '',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email Subject is required',
                        },
                    ],
                },
                {
                    label: 'Email Content',
                    placeholder: '',
                    name: 'emailContent',
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    value: this.emailContent ? this.emailContent : '',
                },
            ],
        };
    }
    createUser(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        if (
            this.checkBoxFormGenInput &&
            this.checkBoxFormGroup.value &&
            this.checkBoxFormGroup.value.send
        ) {
            Helper.markAllFieldAsTouched(this.emailContentFormGroup);
            if (this.emailContentFormGroup.invalid) {
                this.emailContentFormGroup.updateValueAndValidity();
                return;
            }
        }

        buttonRef.loader = true;
        const apiConf: IHitApi = {
            url: ApiUrls.USERS_END_POINT,
            input: this.prepareInputs(),
            function: (response) => {
                buttonRef.loader = false;
                this.notificationService.showSnackBar(
                    'User created successfully'
                );
                this.filtersService.refreshWidget.next(
                    new Set([this.modalService.modalData.sourceId])
                );

                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            errorFunction: (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.notificationService,
                    error,
                    'Error while creating user'
                );
                this.modalService.closeModal(null, this.modalInputData.modalId);
            },
        };

        new HitApi(apiConf, this.listHttpService, this.ngZone).hitApi();
    }
    prepareInputs() {
        const data = this.emailContentFormGroup.value;
        const step1Data = this.multiStepFormService.stepData
            .get(this.modalInputData.modalId)
            .get(1);

        const viewIds = [];
        step1Data['userViewIds'].forEach((ele) => {
            viewIds.push(ele['id']);
        });
        const inputs = {
            firstName: step1Data.firstName,
            lastName: step1Data.lastName,
            viewIds: viewIds,
            notifyWithEmail: false,
            password: step1Data.password,
            confirmPassword: step1Data.password,
            defaultViewId: step1Data.defaultView,
            email: step1Data.email,
            phoneNumber: step1Data.phoneNumber,
            sendingMechanism: {
                sendViaEmail: this.checkBoxFormGroup.value.send,
                emailRequest: {
                    emailTo: [step1Data.email],
                    subject: data.subject,
                    emailBody: data.emailContent,
                },
            },
        };
        return inputs;
    }
}
